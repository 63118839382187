import { createSlice } from "@reduxjs/toolkit";

export const overrideFormDataSlice = createSlice({
  name: "overrideFormData",
  initialState: {
    value: {
      id: -1,
      employee: "",
      employees: [],
      startDate: "",
      endDate: "",
      remarks: "",
      author: "",
      type: "",
    },
  },
  reducers: {
    updateOverrideFormData: (state, action) => {
      state.value = action.payload;
    },
  },
});

export const { updateOverrideFormData } = overrideFormDataSlice.actions;

export default overrideFormDataSlice.reducer;
