import { createSlice } from "@reduxjs/toolkit";

export const complianceRecordSlice = createSlice({
  name: "complianceRecord",
  initialState: { value: {} },
  reducers: {
    updateComplianceRecord: (state, action) => {
      state.value = action.payload;
    },
  },
});

export const { updateComplianceRecord } = complianceRecordSlice.actions;

export default complianceRecordSlice.reducer;
