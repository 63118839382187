import ContentLoader from "react-content-loader";
export const PieChartSkeleton = () => {
  return (
    <ContentLoader
      speed={2}
      width={"100%"}
      height={"100%"}
      viewBox="0 0 400 300"
      backgroundColor="#f3f3f3"
      foregroundColor="#ecebeb"
      style={{ width: "100%" }}
    >
      <rect x="152" y="250" rx="3" ry="3" width="88" height="6" />
      <rect x="168" y="212" rx="3" ry="3" width="52" height="6" />
      <rect x="106" y="231" rx="3" ry="3" width="178" height="6" />
      <circle cx="196" cy="115" r="80" />
    </ContentLoader>
  );
};
