const RightOffCanvas = ({ title, children }) => {
  return (
    <div
      className="offcanvas offcanvas-end"
      tabIndex="-1"
      id="offcanvasEnd"
      aria-labelledby="offcanvasEndLabel"
      style={{ width: "800px" }}
    >
      <div className="offcanvas-header">
        <h2 className="offcanvas-title">{title}</h2>
        <button
          type="button"
          className="btn-close text-reset"
          data-bs-dismiss="offcanvas"
          aria-label="Close"
        ></button>
      </div>
      <div className="offcanvas-body">{children}</div>
    </div>
  );
};
export default RightOffCanvas;
