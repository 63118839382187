import { createSlice } from "@reduxjs/toolkit";

export const selectedTestsSlice = createSlice({
  name: "selectedTests",
  initialState: { value: [] },
  reducers: {
    updateSelectedTests: (state, action) => {
      return { ...state, value: action.payload };
    },
  },
});

export const { updateSelectedTests } = selectedTestsSlice.actions;

export default selectedTestsSlice.reducer;
