/*Third Party */
import {
  IconCircleCheck,
  IconCircleX,
  IconPlugConnected,
  IconPlugConnectedX,
  IconReload,
  IconRocket,
} from "@tabler/icons-react";

/*Local Features*/
import ISP from "./features/isp";
import VPN from "./features/vpn";
import EightByEight from "./features/eightbyeight";
import Microsoft from "./features/microsoft";
import VDI from "./features/vdi";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateSelectedTests } from "./state/reducers";
import { createRef } from "react";
import { useMsal } from "@azure/msal-react";
/* Config */
import { loginRequest } from "config/authentication/azure/authConfig";
import { setAccessToken } from "./services/api/client";

const SelfHelpDesk = () => {
  const [selectedCount, setSelectedCount] = useState(0);
  const { instance, accounts } = useMsal();
  const ispRef = useRef();
  const eightByEightRef = useRef();
  const msRef = useRef();
  const vpnRefs = useRef({});

  const dispatch = useDispatch();
  const selectedTests = useSelector((state) => state.selectedTests.value);

  const vpns = [
    {
      serverName: "vpn.trendmicro.com",
      serverUrl: "http://vpn.trendmicro.com",
      resourceUrl: "/favicon.ico",
    },
    {
      serverName: "phvpn.trendmicro.com",
      serverUrl: "http://phvpn.trendmicro.com",
      resourceUrl: "/favicon.ico",
    },
    {
      serverName: "apacvpn.trendmicro.com",
      serverUrl: "http://apacvpn.trendmicro.com",
      resourceUrl: "/favicon.ico",
    },
  ];
  const initTestMetrics = [
    {
      id: "isp",
      isSelected: false,
    },
    {
      id: "8x8",
      isSelected: false,
    },
    {
      id: "ms",
      isSelected: false,
    },
  ];

  // Update refs when vpns change
  useEffect(() => {
    vpnRefs.current = vpns.reduce((acc, vpn) => {
      acc[vpn.serverName] = acc[vpn.serverName] || createRef();
      return acc;
    }, vpnRefs.current);
  }, [vpns]);

  useEffect(() => {
    const initAccessToken = async () => {
      const { accessToken } = await instance.acquireTokenSilent({
        ...loginRequest,
        account: accounts[0],
      });

      setAccessToken(accessToken);
    };

    initAccessToken();
    const updatedTestStates = initTestMetrics.concat(
      vpns.map((item) => ({
        id: item.serverName,
        isSelected: false,
      }))
    );

    dispatch(updateSelectedTests(updatedTestStates));
  }, []);

  useEffect(() => {
    setSelectedCount(selectedTests.filter((test) => test.isSelected).length);
  }, [selectedTests]);

  const runSelectedTests = () => {
    selectedTests.forEach((test) => {
      const { id, isSelected } = test;
      const ref = vpnRefs.current[id];

      if (isSelected) {
        switch (id) {
          case "isp":
            ispRef.current.runISP();
            break;
          case "8x8":
            eightByEightRef.current.run8x8();
            break;
          case "ms":
            msRef.current.runMicrosoft();
            break;
          default:
            if (id.includes("vpn.trendmicro.com")) {
              // ref.runVPN();
              ref.current.runVPN();
            }
            break;
        }
      }
    });
  };

  return (
    <>
      <div className="container-xl">
        <div className="page-header d-print-none">
          <div className="row align-items-center">
            <div className="col">
              <div className="page-pretitle">Self Diagnostics</div>

              <h2 className="page-title">Service Health Check </h2>
            </div>
            <div className="col-auto ms-auto d-print-none">
              <div className="btn-list">
                {/* <button className="btn btn-white text-muted">
                  <IconReload className="icon" />
                  Refresh
                </button> */}

                <button
                  className="btn btn-primary "
                  onClick={runSelectedTests}
                  disabled={selectedCount <= 0}
                >
                  <IconRocket className="icon" />
                  Run Selected{" "}
                  {selectedCount > 0 ? `(${selectedCount})` : <></>}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="page-body">
        <div className="container-xl">
          <div className="row row-deck row-cards">
            <div className="col-sm-6 col-lg-4">
              <ISP id={"isp"} ref={ispRef} />
            </div>
            <div className="col-sm-6 col-lg-4">
              <EightByEight id={"8x8"} ref={eightByEightRef} />
            </div>
            <div className="col-sm-6 col-lg-4">
              <Microsoft id={"ms"} ref={msRef} />
            </div>
            {vpns.map((vpn, index) => (
              <div className="col-sm-6 col-lg-4" key={index}>
                <VPN
                  id={vpn.serverName}
                  serverName={vpn.serverName}
                  serverUrl={vpn.serverUrl}
                  resourceUrl={vpn.resourceUrl}
                  ref={vpnRefs.current[vpn.serverName]}
                />
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="container-xl">
        <div className="page-header d-print-none">
          <div className="row align-items-center">
            <div className="col">
              <div className="page-pretitle">Self Service</div>

              <h2 className="page-title">VDI Restart (Preview)</h2>
            </div>
          </div>
        </div>
      </div>
      <div className="page-body">
        <div className="container-xl">
          <div className="row row-deck row-cards">
            <VDI />
            {/* <div className="col-md-6">
              <div className="card">
                <div className="progress progress-sm">
                  <div className="progress-bar progress-bar-indeterminate "></div>
                </div>
                <div className="empty">
                  <div className="empty-header text-dark">Azure VDI</div>
                  <p className="empty-title text-muted">
                    In Progress <span className="animated-dots"></span>
                  </p>
                  <p className="empty-subtitle text-muted">
                    Your Azure VDI session is now rebooting. Please wait.
                  </p>
                  <div className="empty-action">
                    <button className="btn btn-primary" disabled>
                      Rebooting <span className="animated-dots"></span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="card">
                <div className="card-header justify-content-between">
                  <div className="col">
                    <div className="page-subtitle pt-0">
                      <div className="">
                        <div className="mt-1 text-success fw-bold">
                          <span className="badge bg-green me-1 "></span>
                          Active
                        </div>
                      </div>
                      <div className="fs-5">azure-vm-name-here</div>
                    </div>
                  </div>
                  <div className="col text-end">
                    <a className="small" href="#">
                      Check Status
                    </a>
                  </div>
                </div>
                <div className="card-body">
                  <div className="empty">
                    <div className=" text-success">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="112"
                        height="112"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        className="pb-2 text-muted"
                      >
                        <path d="M3 5a1 1 0 0 1 1 -1h16a1 1 0 0 1 1 1v10a1 1 0 0 1 -1 1h-16a1 1 0 0 1 -1 -1v-10z"></path>
                        <path d="M7 20h10"></path>
                        <path d="M9 16v4"></path>
                        <path d="M15 16v4"></path>
                      </svg>
                    </div>
                    <div className="empty-header text-dark">AWS VDI</div>
                    <div className="empty-action">
                      <button href="#" className="btn btn-primary">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          className="icon"
                        >
                          <path d="M19.933 13.041a8 8 0 1 1 -9.925 -8.788c3.899 -1 7.935 1.007 9.425 4.747"></path>
                          <path d="M20 4v5h-5"></path>
                        </svg>
                        Restart Connection
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="card">
                <div className="empty">
                  <div className=" text-danger">
                    <IconCircleX width={112} height={112} className="pb-2" />
                  </div>
                  <div className="empty-header text-dark">AWS VDI</div>
                  <p className="empty-title text-danger">Failed</p>
                  <p className="empty-subtitle text-muted">
                    We have encountered an error while trying to reboot your AWS
                    VDI session. Please try again.
                  </p>
                  <div className="empty-action">
                    <button href="#" className="btn btn-primary">
                      <IconReload className="icon" />
                      Reboot
                    </button>
                  </div>
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </>
  );
};
export default SelfHelpDesk;
