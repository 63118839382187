import { createSlice } from "@reduxjs/toolkit";

import {
  previousSunday,
  nextSaturday,
  isSunday,
  isSaturday,
  getWeek,
  startOfWeek,
  endOfWeek,
  format,
} from "date-fns";

const currentDate = new Date();
const startDate = startOfWeek(
  new Date(
    currentDate.getFullYear(),
    currentDate.getMonth(),
    currentDate.getDate() - 7
  )
);
const endDate = endOfWeek(startDate);

export const dateRangeSelected = createSlice({
  name: "dateRangeSelected",
  initialState: {
    value: {
      startDate: format(startDate, "yyyy-MM-dd"),
      endDate: format(endDate, "yyyy-MM-dd"),
      startWeek: getWeek(startDate) - 1,
      endWeek: getWeek(endDate) - 1,
    },
  },
  reducers: {
    updateDateRangeSelected: (state, action) => {
      state.value = action.payload;
    },
  },
});

export const { updateDateRangeSelected } = dateRangeSelected.actions;

export default dateRangeSelected.reducer;
