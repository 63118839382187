import _ from "lodash";
import { eachDayOfInterval } from "date-fns";

export const getAllMembersTableData = (obj) => {
  var members = [];
  if (!_.isEmpty(obj)) {
    members.push(obj);
    const traverse = (obj) => {
      _.forIn(obj, function (val, key) {
        if (_.isArray(val)) {
          val.forEach(function (el) {
            if (_.isObject(el)) {
              if ("emplId" in el) members.push(el);
              traverse(el);
            }
          });
        }
        if (_.isObject(key)) {
          traverse(obj[key]);
        }
      });
    };
    traverse(obj);
  }

  return members;
};

export const getManagersTableData = (obj) => {
  var members = [];

  if (obj.length > 0) {
    // Remove first element contains the root manager
    var rootManager = obj[0];
    _.forEach(obj, (member) => {
      if (
        member.isManager === true &&
        member.teamCount > 0 &&
        member.emplId !== rootManager.emplId
      ) {
        members.push(member);
      }
    });
  }
  return members;
};

export const handleTeamTableRowClick = (obj, id) => {
  return _.filter(obj, (x) => x.emplId === id);
};

export const groupedByWeek = (obj) => {
  const res = _.mapValues(_.groupBy(obj, "week"), (clist) =>
    clist.map((record) => _.omit(record, "week"))
  );
  return res;
};

export const getDateRangeOfWeek = (weekNo) => {
  //var result = startOfWeek(new Date(weekNo));
  return "[startDate] - [endDate]";
};
export const getAllDatesWithinRange = (startDate, endDate) => {
  return eachDayOfInterval({
    start: new Date(startDate),
    end: new Date(endDate),
  });
};
