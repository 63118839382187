import { createSlice } from "@reduxjs/toolkit";
import { format, startOfMonth, endOfMonth } from "date-fns";

var currentDate = new Date();

export const monthSelected = createSlice({
  name: "monthSelected",
  initialState: {
    value: {
      startDate: format(startOfMonth(currentDate), "yyyy-MM-dd"),
      endDate: format(endOfMonth(currentDate), "yyyy-MM-dd")
    },
  },
  reducers: {
    updateMonthSelected: (state, action) => {
      state.value = action.payload;
    },
  },
});

export const { updateMonthSelected } = monthSelected.actions;
export default monthSelected.reducer;
