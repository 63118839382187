import React from "react";
import { format } from "date-fns";
import { DateRangePicker } from "rsuite";
import "rsuite/dist/rsuite.min.css";

export const MonthSelector = ({ dateSelected, setDateSelected }) => {
  return (
    <>
      <DateRangePicker
        oneTap
        showOneCalendar
        hoverRange="month"
        ranges={[]}
        cleanable={false}
        editable={false}
        value={[
          new Date(dateSelected.startDate),
          new Date(dateSelected.endDate),
        ]}
        placement="bottomEnd"
        onChange={(dateRange) => {
          const startDate = new Date(dateRange[0]);
          const endDate = new Date(dateRange[1]);
          const dateSelectedObj = {
            startDate: format(startDate, "yyyy-MM-dd"),
            endDate: format(endDate, "yyyy-MM-dd")
          };
          setDateSelected(dateSelectedObj);
        }}
        format="MMMM dd, yyyy"
      />
    </>
  );
};
