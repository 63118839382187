/* Third Party */
import { Route, Routes } from "react-router-dom";
import { Provider } from "react-redux";
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useMsal,
} from "@azure/msal-react";

/* Common Components */
import Header from "components/common/header";
import Footer from "components/common/footer";
import NavigationBar from "components/common/navigationBar";

/* Pages */
import OfficeMonitoring from "pages/officeMonitoring";
import Timelog from "pages/timelog";
import SelfHelpDesk from "pages/selfHelpDesk";
import {
  Unauthorized,
  BadRequest,
  Forbidden,
  NotFound,
  InternalServerError,
} from "pages/errors";

/* CSS */
import "assets/css/tabler.min.css";
import "assets/css/tabler-flags.min.css";
import "assets/css/tabler-payments.min.css";
import "assets/css/tabler-vendors.min.css";
import "assets/css/demo.min.css";

/* JS */
import "assets/js/demo.min.js";
import "assets/js/tabler.min.js";

/* Store */
import { store as officeMonitoringStore } from "pages/officeMonitoring/state/stores";
import { store as selfHelpDeskStore } from "pages/selfHelpDesk/state/stores";
/* Config */
import { loginRequest } from "config/authentication/azure/authConfig";

const ForceLogin = () => {
  const { instance } = useMsal();
  instance.loginRedirect(loginRequest).catch((e) => {
    console.log(e);
  });
  return <></>;
};

const Default = ({ children }) => {
  return (
    <>
      <AuthenticatedTemplate>
        <div className="layout-default">
          <div className="wrapper">
            <Header />
            <NavigationBar />
            <div className="page-wrapper">
              <Routes>
                <Route
                  path="/"
                  element={
                    <Provider store={officeMonitoringStore}>
                      <OfficeMonitoring />
                    </Provider>
                  }
                />
                <Route
                  path="/timelog"
                  element={
                    <Provider store={officeMonitoringStore}>
                      <Timelog />
                    </Provider>
                  }
                />
                <Route
                  path="/selfhelpdesk"
                  element={
                    <Provider store={selfHelpDeskStore}>
                      <SelfHelpDesk />
                    </Provider>
                  }
                />
                <Route path="/400" element={<BadRequest />} />
                <Route path="/401" element={<Unauthorized />} />
                <Route path="/403" element={<Forbidden />} />
                <Route path="/404" element={<NotFound />} />
                <Route path="/500" element={<InternalServerError />} />
                <Route path="*" element={<NotFound />} />
              </Routes>
            </div>
            <Footer />
          </div>
        </div>
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <ForceLogin />
      </UnauthenticatedTemplate>
    </>
  );
};
export default Default;
