import { IconArrowLeft } from "@tabler/icons-react";
export const BadRequest = () => {
  return (
    <div className="page page-center border-top-wide border-primary">
      <div className="container-tight py-4">
        <div className="empty">
          <div className="empty-header">400</div>
          <p className="empty-title">Oops… Bad Request</p>
          <p className="empty-subtitle text-muted">
            We have received a malformed or invalid request. Kindly contact the
            administrator of this page and report the issue.
          </p>
          <div className="empty-action">
            <a href="/" className="btn btn-primary">
              <IconArrowLeft className="icon" />
              Take me home
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};
