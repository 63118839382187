/*Third Party */
import { useEffect, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateActiveUser, updateComplianceRecord } from "./state/reducers";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useMsal } from "@azure/msal-react";
import { IconReload } from "@tabler/icons-react";
import { Link } from "react-router-dom";

/*Local Features*/
import { ComplianceRateChart } from "./features/overallComplianceRate";
import { EmployeesPerDayChart } from "./features/employeesPerDay";
import {
  AllMembersTable,
  UserRecordsCanvas,
  ManagersTable,
} from "./features/teamTable";
import { DateRangeSelector } from "./features/dateRangeSelector";
import {
  CreateComplianceOverrideModal,
  EditComplianceOverrideModal,
} from "components/modules/complianceOverride";
import { DeleteComplianceOverrideModal } from "components/modules/complianceOverride";
/* API Methods */
import { getComplianceRecord, getEmployee } from "./services/api";

/* Features-Utils */
import { getComplianceRateChartData } from "./features/overallComplianceRate/utils";
import { getEmployeesPerDayChartData } from "./features/employeesPerDay/utils";
import {
  getAllMembersTableData,
  getManagersTableData,
} from "./features/teamTable/utils";

import axios from "axios";
import {
  loginRequest,
  selfServiceTokenRequest,
} from "config/authentication/azure/authConfig";
import { setAccessToken, setSelfServiceToken } from "./services/api/client";
/* Config */

/*UI Component*/
import { AlertPrimary, AlertReleaseNotes } from "components/ui/alert";
const OfficeMonitoring = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { instance, accounts } = useMsal();
  const [selectedUser, setSelectedUser] = useState({});
  const [dateSelected, setDateSelected] = useState(
    useSelector((state) => state.dateRangeSelected.value)
  );

  const [complianceRecord, setComplianceRecord] = useState({});
  const [complianceRecordChartData, setComplianceRecordChartData] = useState();
  const [employeesPerDayChartData, setEmployeesPerDayChartData] = useState([]);
  const [allMembersTableData, setAllMembersTableData] = useState([]);
  const [managersTableData, setManagersTableData] = useState([]);

  let [searchParams] = useSearchParams();
  const user = searchParams.get("user");

  const [currentUser, setCurrentUser] = useState({});

  const [isLoading, setIsLoading] = useState(true);
  const [isSameUser, setIsSameUser] = useState(true);
  const activeUser = useSelector((state) => state.activeUser.value);

  const renderData = async () => {
    setIsLoading(true);
    setIsSameUser(true);

    try {
      const { accessToken } = await instance.acquireTokenSilent({
        ...loginRequest,
        account: accounts[0],
      });

      setAccessToken(accessToken);

      const userEmail = user ?? accounts[0].username;

      const complianceRecord = await getComplianceRecord(
        userEmail,
        dateSelected.startDate,
        dateSelected.endDate
      );

      setIsSameUser(userEmail === accounts[0].username);
      setComplianceRecord(complianceRecord.data);
      setIsLoading(false);

      const userInfo = await getEmployee(userEmail);
      setCurrentUser(userInfo.data);

      userEmail === accounts[0].username
        ? dispatch(updateActiveUser(userInfo.data))
        : dispatch(
            updateActiveUser((await getEmployee(accounts[0].username)).data)
          );
    } catch (error) {
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        navigate(`/${error.response.status}`);
      } else if (error.request) {
        // The request was made but no response was received
        console.error(error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        console.error("Error", error.message);
      }
    }

    dispatch(updateComplianceRecord(complianceRecord));
  };

  useEffect(() => {
    renderData();
  }, [dateSelected, user]);

  const memoizedGetComplianceRateChartData = useMemo(
    () => getComplianceRateChartData(complianceRecord),
    [complianceRecord]
  );

  const memoizedGetEmployeesPerDayChartData = useMemo(
    () => getEmployeesPerDayChartData(complianceRecord),
    [complianceRecord]
  );

  const memoizedGetAllMembersTableData = useMemo(
    () => getAllMembersTableData(complianceRecord),
    [complianceRecord]
  );

  const memoizedGetManagerTableData = useMemo(
    () => getManagersTableData(allMembersTableData),
    [allMembersTableData]
  );

  useEffect(() => {
    setComplianceRecordChartData(memoizedGetComplianceRateChartData);
    setEmployeesPerDayChartData(memoizedGetEmployeesPerDayChartData);
    setAllMembersTableData(memoizedGetAllMembersTableData);
  }, [complianceRecord]);

  useEffect(() => {
    setManagersTableData(memoizedGetManagerTableData);
  }, [allMembersTableData]);

  return (
    <> 
      {(activeUser.isAdmin === true|| activeUser.tmManageable === true) && (
        <>
          <CreateComplianceOverrideModal
            author={accounts[0].username}
            renderData={renderData}
          />
          <EditComplianceOverrideModal
            author={accounts[0].username}
            renderData={renderData}
          />
          <DeleteComplianceOverrideModal renderData={renderData} />
        </>
      )}
      <UserRecordsCanvas selectedUser={selectedUser} />
      <div className="container-xl">
        <div className="page-header d-print-none">
          {!isSameUser && !isLoading ? (
            <AlertPrimary>
              {" "}
              You are viewing{" "}
              <strong className="">
                {currentUser.name} {"'s"}
              </strong>{" "}
              Dashboard. Click{" "}
              <Link className="text-decoration-underline" to={"/"}>
                here
              </Link>{" "}
              to view your dashboard
            </AlertPrimary>
          ) : null}

          <AlertReleaseNotes />

          <div className="row align-items-center">
            <div className="col">
              <div className="page-pretitle">Dashboard </div>
              {isLoading ? (
                <h2 className="page-title">
                  <p>
                    Loading <span className="animated-dots"></span>
                  </p>
                </h2>
              ) : (
                <h2 className="page-title">{currentUser.name}</h2>
              )}
            </div>
            <div className="col-auto ms-auto d-print-none">
              <div className="btn-list">
                <span>
                  <button
                    className="btn btn-white text-muted"
                    disabled={isLoading}
                    onClick={() => renderData()}
                  >
                    <IconReload className="icon" />
                    Refresh
                  </button>
                </span>

                <span className="">
                  <DateRangeSelector
                    setDateSelected={setDateSelected}
                    dateSelected={dateSelected}
                  />
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="page-body">
        <div className="container-xl">
          <div className="row row-deck row-cards">
            <div className="col-sm-6 col-lg-4">
              <ComplianceRateChart
                data={complianceRecordChartData}
                isLoading={isLoading}
              />
            </div>
            <div className="col-lg-8">
              <EmployeesPerDayChart
                data={employeesPerDayChartData}
                isLoading={isLoading}
              />
            </div>
            <div className="col-md-12">
              {managersTableData.length > 0 ? (
                <ManagersTable data={managersTableData} isLoading={isLoading} />
              ) : (
                <></>
              )}
            </div>
            <div className="col-12">
              <AllMembersTable
                data={allMembersTableData}
                setSelectedUser={setSelectedUser}
                isLoading={isLoading}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default OfficeMonitoring;
