import { useState } from "react";
import { useSelector } from "react-redux";
import { IconAlertTriangle } from "@tabler/icons-react";
import { Notification, useToaster } from "rsuite";
import { useNavigate } from "react-router-dom";
import { deleteComplianceOverride } from "pages/officeMonitoring/services/api";
import { format, isValid } from "date-fns";
export const DeleteComplianceOverrideModal = ({ renderData }) => {
  const { id, startDate, endDate } = useSelector(
    (state) => state.overrideFormData.value
  );
  const [isLoading, setIsLoading] = useState(false);
  const toaster = useToaster();
  const navigate = useNavigate();

  let date = "";
  if (
    startDate &&
    endDate &&
    isValid(new Date(startDate)) &&
    isValid(new Date(endDate))
  ) {
    date =
      startDate !== endDate
        ? format(new Date(startDate), "MMMM dd, yyyy") +
          " - " +
          format(new Date(endDate), "MMMM dd, yyyy")
        : format(new Date(startDate), "MMMM dd, yyyy");
  }

  const onSubmit = () => {
    setIsLoading(true);
    const modalElement = document.getElementById("modal-delete-override");
    const modal = window.bootstrap.Modal.getInstance(modalElement);

    const offCanvasElement = document.getElementById("offcanvasEnd");
    const offcanvas = window.bootstrap.Offcanvas.getInstance(offCanvasElement);

    deleteComplianceOverride(id)
      .then(() => {
        toaster.push(
          <Notification type="success" header="Submitted Successfully" closable>
            Your office attendance override has been removed
          </Notification>,
          {
            placement: "topEnd",
            duration: 5000,
          }
        );
        renderData();
      })
      .catch((error) => {
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx

          switch (error.response.status) {
            case 401: {
              toaster.push(
                <Notification type="error" header="Unauthorized" closable>
                  The operation failed. You don't have permission to perform
                  this action.
                </Notification>,
                {
                  placement: "topEnd",
                  duration: 5000,
                }
              );
              break;
            }
            case 400: {
              toaster.push(
                <Notification type="error" header="Bad Request" closable>
                  The operation failed. We have received a malformed or invalid
                  request.
                </Notification>,
                {
                  placement: "topEnd",
                  duration: 5000,
                }
              );
              break;
            }
            case 404: {
              toaster.push(
                <Notification type="error" header="Not Found" closable>
                  The operation failed. No entry was found.
                </Notification>,
                {
                  placement: "topEnd",
                  duration: 5000,
                }
              );
              break;
            }
            default:
              navigate(`/${error.response.status}`);
          }
        } else {
          toaster.push(
            <Notification type="error" header="Something went wrong" closable>
              The operation failed. Please try again later or contact the
              administrator of this page.
            </Notification>,
            {
              placement: "topEnd",
              duration: 5000,
            }
          );
        }
      })
      .finally(() => {
        setIsLoading(false);
        modal.hide();
        offcanvas.hide();
      });
  };

  return (
    <>
      <div
        className="modal modal-blur fade"
        id="modal-delete-override"
        tabIndex="-1"
        role="dialog"
        aria-modal="true"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
      >
        <div
          className="modal-dialog modal-sm modal-dialog-centered"
          role="document"
        >
          <div className="modal-content">
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
            <div className="modal-status bg-danger"></div>
            <div className="modal-body text-center py-4">
              <IconAlertTriangle className="icon mb-2 text-danger icon-lg" />
              <h3>Are you sure?</h3>
              <div className="text-muted">
                Do you really want to remove the override that is currently set
                for <strong>{date}</strong>? This cannot be undone.
              </div>
            </div>
            <div className="modal-footer">
              <div className="w-100">
                <div className="row">
                  <div className="col">
                    <a href="#" className="btn w-100" data-bs-dismiss="modal">
                      No
                    </a>
                  </div>
                  <div className="col">
                    <button
                      type="button"
                      className="btn btn-danger w-100"
                      disabled={isLoading}
                      onClick={() => onSubmit()}
                    >
                      {isLoading ? (
                        <>
                          Loading <span className="animated-dots"></span>
                        </>
                      ) : (
                        "Yes"
                      )}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
