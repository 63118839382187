import {
  IconInfoCircle,
  IconAlertTriangle,
  IconAlertCircle,
  IconCheck,
  IconSparkles,
} from "@tabler/icons-react";

import { CURRENT_RELEASE_NOTES, ALL_RELEASE_NOTES } from "constants/global";
export const AlertPrimary = ({ children, ...prop }) => {
  return (
    <div className="alert alert-primary mb-3">
      <div className="d-flex align-items-center">
        <div>
          <IconInfoCircle className="icon alert-icon" />
        </div>
        <div>{children}</div>
      </div>
    </div>
  );
};
export const AlertSecondary = ({ children, ...prop }) => {
  return (
    <div className="alert alert-secondary mb-3">
      <div className="d-flex align-items-center">
        <div>
          <IconInfoCircle className="icon alert-icon" />
        </div>
        <div>{children}</div>
      </div>
    </div>
  );
};
export const AlertInfo = ({ children, ...prop }) => {
  return (
    <div className="alert alert-info mb-3">
      <div className="d-flex align-items-center">
        <div>
          <IconInfoCircle className="icon alert-icon" />
        </div>
        <div>{children}</div>
      </div>
    </div>
  );
};
export const AlertSuccess = ({ children, ...prop }) => {
  return (
    <div className="alert alert-success mb-3">
      <div className="d-flex align-items-center">
        <div>
          <IconCheck className="icon alert-icon" />
        </div>
        <div>{children}</div>
      </div>
    </div>
  );
};
export const AlertWarning = ({ children, ...prop }) => {
  return (
    <div className="alert alert-warning mb-3">
      <div className="d-flex align-items-center">
        <div>
          <IconAlertTriangle className="icon alert-icon" />
        </div>
        <div>{children}</div>
      </div>
    </div>
  );
};
export const AlertDanger = ({ children, ...prop }) => {
  return (
    <div className="alert alert-danger mb-3">
      <div className="d-flex align-items-center">
        <div>
          <IconAlertCircle className="icon alert-icon" />
        </div>
        <div>{children}</div>
      </div>
    </div>
  );
};
export const AlertDark = ({ children, ...prop }) => {
  return (
    <div className="alert alert-dark mb-3">
      <div className="d-flex align-items-center">
        <div>
          <IconInfoCircle className="icon alert-icon" />
        </div>
        <div>{children}</div>
      </div>
    </div>
  );
};

export const AlertReleaseNotes = () => {
  return (
    <div className="alert alert-primary alert-dismissible " role="alert">
      <div className="d-flex">
        <div>
          <h3 className="text-primary d-flex align-items-center">
            What's New
            <span className="ms-2 badge bg-dark-lt">v1.11.0</span>
          </h3>
          <p>
            <IconSparkles className="text-yellow" />
            <strong>
              Self-Service Logoff Feature for Azure Virtual Desktop Users
            </strong>
            <IconSparkles className="text-yellow" />
            <span className="ms-2 badge bg-primary-lt">New Feature</span>
            <span className="ms-2 badge bg-warning-lt">Preview</span>
          </p>
          <ul className="text-muted">
            <li>
              <p>
                This new capability allows you to securely and efficiently log
                off from your virtual desktop interface, giving you more control
                and flexibility over your workspace. You may click on{" "}
                <a href={CURRENT_RELEASE_NOTES}>this link</a> for further
                information and instructions on how to use this feature.{" "}
              </p>
              <p>
                <strong>Note:</strong> Being in the Preview phase, this feature
                is still under development, which means you might come across
                some glitches or unexpected behavior. We're actively working on
                refining the feature, and your patience and feedback during this
                period are incredibly valuable to us.
              </p>
            </li>
          </ul>

          <div className="text-muted">
            <i>
              Read the{" "}
              <a
                href={CURRENT_RELEASE_NOTES}
                className="text-decoration-underline"
                target="_blank"
              >
                full release notes
              </a>{" "}
              and see how it all works!
            </i>
          </div>

          <div className="text-muted d-flex justify-content-end">
            <small>
              <a href={ALL_RELEASE_NOTES} target="_blank">
                View release notes
              </a>
            </small>
          </div>
        </div>
      </div>

      <a className="btn-close" data-bs-dismiss="alert" aria-label="close"></a>
    </div>
  );
};
