/*Third Party */
import {
  IconAlertCircle,
  IconAlertCircleFilled,
  IconCircleCheck,
  IconCircleCheckFilled,
  IconCircleDotted,
  IconCircleX,
  IconCircleXFilled,
  IconClock,
  IconClockFilled,
  IconExternalLink,
  IconInfoCircle,
  IconInfoCircleFilled,
} from "@tabler/icons-react";
import { getMSServices } from "pages/selfHelpDesk/services/api";
import { updateSelectedTests } from "pages/selfHelpDesk/state/reducers";
import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

/*Local Features*/

/* API Methods */

/* Features-Utils */

/* Config */

/*UI Component*/

const Microsoft = forwardRef(({ id }, ref) => {
  const dispatch = useDispatch();
  const [isMSSelected, setIsMSSelected] = useState(false);
  const [isMicrosoftRunning, setIsMicrosoftRunning] = useState(false);

  const [msData, setMSData] = useState({
    title: "Not Tested",
    subTitle: "",
    services: [
      {
        id: "Outlook.com",
        name: "Outlook.com",
        isUp: null,
        messages: null,
      },
      {
        id: "TeamsforLife",
        name: "Teams (Consumer)",
        isUp: null,
        messages: null,
      },
      {
        id: "M365Consumer",
        name: "Microsoft 365 (Consumer)",
        isUp: null,
        messages: null,
      },
      {
        id: "OfficeOnlineConsumer",
        name: "Office for the web (Consumer)",
        isUp: null,
        messages: null,
      },
    ],
    isAllUp: null,
  });
  const selectedTests = useSelector((state) => state.selectedTests.value);

  useEffect(() => {
    const isSelected = selectedTests.find(
      (metric) => metric.id === id
    )?.isSelected;

    if (isSelected) {
      setIsMSSelected(isSelected);
    }
  }, [selectedTests]);

  useEffect(() => {
    const updatedSelectedTests = selectedTests.map((test) => {
      if (test.id === id) {
        return { ...test, isSelected: !test.isSelected };
      }
      return test;
    });
    dispatch(updateSelectedTests(updatedSelectedTests));
  }, [isMSSelected]);

  async function runMicrosoft() {
    if (!isMicrosoftRunning) {
      setIsMicrosoftRunning(true);
      try {
        const apiResponse = await getMSServices();

        const data = apiResponse.data;

        // Filter services based on the IDs available in the default list
        const availableServices = data.services.filter((service) =>
          msData.services.some(
            (defaultService) => defaultService.id === service.id
          )
        );

        // Sort the available services based on the length of the "name" property
        const sortedServices = availableServices.sort(
          (a, b) => a.name.length - b.name.length
        );

        // Check if all services are up
        const isAllServicesUp = sortedServices.every(
          (service) => service.isUp === true
        );

        // Set the sorted and available services array along with other properties to the state
        setMSData({
          title: isAllServicesUp ? "We're all good!" : "There's an issue",
          subTitle: isAllServicesUp
            ? "Everything is up and running."
            : "Some of the services are down",
          services: sortedServices,
          isAllUp: isAllServicesUp,
        });
      } catch (error) {
        console.error("Error fetching data:", error);
        setMSData((prevData) => ({
          ...prevData, // Spread the current state to retain other properties
          title: "Error",
          subTitle: "Something went wrong",
        }));
      }
      setIsMicrosoftRunning(false);
    }
  }

  useImperativeHandle(ref, () => ({
    runMicrosoft,
  }));
  return (
    <>
      <div className={`card  ${isMSSelected ? "border-primary border-2" : ""}`}>
        {isMicrosoftRunning ? (
          <div className="progress progress-sm">
            <div className="progress-bar progress-bar-indeterminate "></div>
          </div>
        ) : (
          <></>
        )}
        <div
          className="card-header "
          style={{ zIndex: 1 }}
          // onClick={() => setIsMSSelected(!isMSSelected)}
        >
          <div className="subheader text-dark d-flex align-items-end">
            Microsoft Services
            <a
              href="https://portal.microsoft.com/servicestatus"
              target="_blank"
            >
              <IconExternalLink size={16} className="ms-1" />
            </a>
          </div>
          <div className="ms-auto lh-1">
            <label className="form-check form-switch m-0 ">
              <input
                className="form-check-input position-static cursor-pointer"
                type="checkbox"
                checked={isMSSelected}
                onChange={() => setIsMSSelected(!isMSSelected)}
              />
            </label>
          </div>
        </div>

        <div className="card-body">
          <div className="small text-muted">{msData.subTitle}</div>
          <div className="h1 mb-3 text-muted">
            {isMicrosoftRunning ? (
              <>
                In Progress <span className="animated-dots"></span>
              </>
            ) : (
              <span
                className={`d-flex align-items-center ${
                  msData.isAllUp == null
                    ? "text-muted"
                    : msData.isAllUp
                    ? "text-success"
                    : "text-danger"
                }`}
              >
                {msData.isAllUp ? (
                  <IconCircleCheckFilled className="text-success me-1" />
                ) : (
                  <></>
                )}
                {msData.title}
              </span>
            )}
          </div>
          <div className="small text-muted pb-1">Services:</div>
          <div className="row">
            {msData &&
              msData.services?.map((value, index) => (
                <div className="col-sm-6" key={index}>
                  {isMicrosoftRunning ? (
                    <div
                      className="spinner-border spinner-border-sm me-1 "
                      role="status"
                      style={{ width: "14px", height: "14px" }}
                    ></div>
                  ) : value.isUp == null ? (
                    <IconCircleDotted width="14" className="text-muted me-1" />
                  ) : value.isUp ? (
                    <IconCircleCheck width="16" className="text-success me-1" />
                  ) : (
                    <IconCircleX width="16" className="text-danger me-1" />
                  )}
                  <small className="fs-5">{value.name}</small>
                </div>
              ))}
          </div>
        </div>
        <div className="card-footer">
          <div className="row ">
            <div className="col" style={{ zIndex: 1 }}>
              {isMicrosoftRunning ? (
                <button className="btn btn-primary  w-100 fs-5" disabled>
                  Running <span className="animated-dots"></span>
                </button>
              ) : (
                <button
                  className="btn btn-outline-primary  w-100 fs-5"
                  onClick={runMicrosoft}
                >
                  Check Status
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
});
export default Microsoft;
