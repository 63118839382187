import { Link, useMatch, useResolvedPath } from "react-router-dom";

export const CustomLink = ({ to, children, ...props }) => {
  const resolvedPath = useResolvedPath(to);
  const isActive = useMatch({ path: resolvedPath.pathname, end: true });
  return (
    <li className={isActive ? "nav-item active" : "nav-item"}>
      <Link className="nav-link" to={to}>
        {children}
      </Link>
    </li>
  );
};
