import { IconArrowLeft } from "@tabler/icons-react";
export const InternalServerError = () => {
  return (
    <div className="page page-center border-top-wide border-primary">
      <div className="container-tight py-4">
        <div className="empty">
          <div className="empty-header">500</div>
          <p className="empty-title">Oops… Internal Server Error</p>
          <p className="empty-subtitle text-muted">
            We are sorry but our server encountered an internal error. Kindly
            contact the administrator of this page and report the issue.
          </p>
          <div className="empty-action">
            <a href="/" className="btn btn-primary">
              <IconArrowLeft className="icon" />
              Take me home
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};
