/* Third Party */
import React from "react";
import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";

/* Layout Components */
import Default from "./components/layouts/default";

import { msalConfig } from "../src/config/authentication/azure/authConfig";

const msalInstance = new PublicClientApplication(msalConfig);

const App = () => {
  return (
    <MsalProvider instance={msalInstance}>
      <Default />
    </MsalProvider>
  );
};

export default App;
