import ContentLoader from "react-content-loader";
export const BarChartSkeleton = () => {
  return (
    <ContentLoader width={"100%"} height={300} viewBox="0 0 400 200">
      <rect x="40" y="150" rx="0" ry="0" width="25" height="40" />
      <rect x="80" y="135" rx="0" ry="0" width="25" height="55" />
      <rect x="120" y="116" rx="0" ry="0" width="25" height="74" />
      <rect x="160" y="70" rx="0" ry="0" width="25" height="120" />
      <rect x="200" y="132" rx="0" ry="0" width="25" height="58" />
      <rect x="240" y="100" rx="0" ry="0" width="25" height="90" />
      <rect x="280" y="150" rx="0" ry="0" width="25" height="40" />
      <rect x="320" y="160" rx="0" ry="0" width="25" height="30" />
    </ContentLoader>
  );
};
