/*Third Party */
import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { JQSpeedTest } from "./jqspeedtest";
import { useDispatch, useSelector } from "react-redux";
import { updateSelectedTests } from "pages/selfHelpDesk/state/reducers";
import { getISP } from "pages/selfHelpDesk/services/api";
import { IconCircleCheckFilled, IconCircleXFilled } from "@tabler/icons-react";
/*Local Features*/

/* API Methods */

/* Features-Utils */

/* Config */

/*UI Component*/

const ISP = forwardRef(({ id }, ref) => {
  const dispatch = useDispatch();

  const [isISPSelected, setIsISPSelected] = useState(false);

  const [isISPDownloadLoading, setIsISPDownloadLoading] = useState(false);
  const [isISPUploadLoading, setIsISPUploadLoading] = useState(false);
  const [isISPNameLoading, setIsISPNameLoading] = useState(false);
  const [isISPRunning, setISPRunning] = useState(false);

  const [iSPName, setISPName] = useState("No result yet");
  const [speedtestData, setSpeedtestData] = useState({
    download: "No result yet",
    upload: "No result yet",
    latency: "No result yet",
  });

  const [status, setStatus] = useState({
    icon: <></>,
    color: "dark",
    title: "Not Tested",
    subTitle: "",
  });
  const selectedTests = useSelector((state) => state.selectedTests.value);

  useEffect(() => {
    const isSelected = selectedTests.find(
      (metric) => metric.id === id
    )?.isSelected;

    if (isSelected) {
      setIsISPSelected(isSelected);
    }
  }, [selectedTests]);

  useEffect(() => {
    const updatedSelectedTests = selectedTests.map((test) => {
      if (test.id === id) {
        return { ...test, isSelected: !test.isSelected };
      }
      return test;
    });
    dispatch(updateSelectedTests(updatedSelectedTests));
  }, [isISPSelected]);

  useEffect(() => {
    if (
      isISPRunning ||
      speedtestData.download.includes("No result yet") ||
      speedtestData.upload.includes("No result yet")
    ) {
      return;
    }

    const download = parseFloat(speedtestData.download.split(" ")[0]);
    const upload = parseFloat(speedtestData.upload.split(" ")[0]);

    if (!isNaN(download) && !isNaN(upload)) {
      const getSpeedStatus = (speed) => {
        if (speed < 1) return 0; // slow
        else if (speed < 5) return 1; // normal
        else if (speed < 15) return 2; // fast
        else return 3; // very fast
      };

      const finalStatus = getSpeedStatus(Math.min(download, upload));

      const getStatusData = (Icon, color, title, subTitle) => ({
        icon: <Icon className={`text-${color} me-1`} />,
        color: `text-${color}`,
        title,
        subTitle,
      });

      const statusOptions = [
        {
          Icon: IconCircleXFilled,
          color: "danger",
          title: "Slow",
          subTitle: "Your internet connection is:",
        },
        {
          Icon: IconCircleCheckFilled,
          color: "success",
          title: "Normal",
          subTitle: "Your internet connection is:",
        },
        {
          Icon: IconCircleCheckFilled,
          color: "success",
          title: "Fast",
          subTitle: "Your internet connection is:",
        },
        {
          Icon: IconCircleCheckFilled,
          color: "success",
          title: "Excellent",
          subTitle: "Your internet connection is:",
        },
      ];

      setStatus(
        getStatusData(
          statusOptions[finalStatus].Icon,
          statusOptions[finalStatus].color,
          statusOptions[finalStatus].title,
          statusOptions[finalStatus].subTitle
        )
      );
    }
  }, [speedtestData, isISPRunning, setStatus]);

  const getNetworkDownloadSpeed = async () => {
    setIsISPDownloadLoading(true);
    setIsISPUploadLoading(true);

    new JQSpeedTest({
      testStateCallback: speedTestCallBackFunctionState,
      testFinishCallback: speedTestCallbackFunctionFinish,
      testDlCallback: speedTestCallbackFunctionDl,
      testUlCallback: speedTestCallbackFunctionUl,
      // testReCallback:  speedTestCallbackFunctionRT,
      testImageUrl: "https://r2w.trendmicro.com.ph/speedtest-dl.png",
      testImageSize: 5565757,
      countDlSamples: 1,
      testUploadSize: 5565757,
      testUploadUrl: `${process.env.REACT_APP_OM_API_URL}/readytowork`,
    });
  };

  /** Call Back Function for Download Result **/
  function speedTestCallbackFunctionDl(value, duration) {
    console.log("Download: ", value, duration);
    setSpeedtestData((prevState) => ({ ...prevState, download: value }));
    setIsISPDownloadLoading(false);
  }
  /** Call Back Function for Upload Result **/
  function speedTestCallbackFunctionUl(value, duration) {
    console.log("Upload: ", value, duration);
    setSpeedtestData((prevState) => ({ ...prevState, upload: value }));
    setIsISPUploadLoading(false);
  }
  /** Call Back Function for Response Time **/
  function speedTestCallbackFunctionFinish(value) {
    console.log("Finished: ", value);
    setISPRunning(false);
  }

  /** Call Back Function for Start **/
  function speedTestCallBackFunctionState(value) {
    console.log("State: ", value);
  }
  const fetchISPData = async () => {
    setIsISPNameLoading(true);
    try {
      // Fetch IP address from the first API
      const ipResponse = await fetch("https://api.ipify.org/?format=json");
      const { ip } = await ipResponse.json();

      // Fetch additional data from the second API using the obtained IP
      const apiResponse = await getISP(ip);
      const data = apiResponse.data;

      if (data.status == "success") {
        setISPName(data.isp);
      } else {
        setISPName("Unable to fetch");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setISPName("Unable to fetch");
    }
    setIsISPNameLoading(false);
  };
  const runISP = () => {
    if (!isISPRunning) {
      setISPRunning(true);
      fetchISPData();
      getNetworkDownloadSpeed();
    }
  };

  useImperativeHandle(ref, () => ({
    runISP,
  }));
  return (
    <>
      <div
        className={`card  ${isISPSelected ? "border-primary border-2" : ""}`}
      >
        {isISPRunning ? (
          <div className="progress progress-sm">
            <div className="progress-bar progress-bar-indeterminate "></div>
          </div>
        ) : (
          <></>
        )}
        <div
          className="card-header cursor-pointer"
          style={{ zIndex: 1 }}
          onClick={() => setIsISPSelected(!isISPSelected)}
        >
          <div className="subheader text-dark">Internet Service Provider</div>
          <div className="ms-auto lh-1">
            <label className="form-check form-switch m-0 ">
              <input
                className="form-check-input position-static cursor-pointer"
                type="checkbox"
                checked={isISPSelected}
                onChange={() => setIsISPSelected(!isISPSelected)}
              />
            </label>
          </div>
        </div>
        <div className="card-body">
          <div className="small text-muted">{status.subTitle}</div>
          <div className="h1 mb-3 text-muted">
            {isISPRunning ? (
              <>
                In Progress <span className="animated-dots"></span>
              </>
            ) : (
              <span className={`d-flex align-items-center ${status.color}`}>
                {status.icon}
                {status.title}
              </span>
            )}
          </div>
          <div className="row mb-2">
            <div className="col-6">
              <div className="small fw-bold">ISP Name:</div>
            </div>
            <div className="col-6 small text-end">
              {isISPNameLoading ? (
                <span className="text-muted">
                  <div
                    className="spinner-border spinner-border-sm"
                    role="status"
                  ></div>
                </span>
              ) : (
                <span>{`${iSPName}`}</span>
              )}
            </div>
          </div>
          <div className="row mb-2">
            <div className="col-6">
              <div className="small fw-bold">Download Speed:</div>
            </div>
            <div className="col-6 small text-end">
              {isISPDownloadLoading ? (
                <span className="text-muted">
                  <div
                    className="spinner-border spinner-border-sm"
                    role="status"
                  ></div>
                </span>
              ) : (
                <span>{`${speedtestData.download}`}</span>
              )}
            </div>
          </div>
          <div className="row mb-2">
            <div className="col-6">
              <div className="small fw-bold">Upload Speed:</div>
            </div>
            <div className="col-6 small text-end">
              {isISPUploadLoading ? (
                <span className="text-muted">
                  <div
                    className="spinner-border spinner-border-sm"
                    role="status"
                  ></div>
                </span>
              ) : (
                <span>{`${speedtestData.upload}`}</span>
              )}
            </div>
          </div>
          {/* <div className="row mb-2">
            <div className="col-6">
              <div className="small fw-bold">Latency:</div>
            </div>
            <div className="col-6 small text-end">
              <span>{`${speedtestData.latency}`}</span>
            </div>
          </div> */}
        </div>
        <div className="card-footer">
          <div className="row ">
            <div className="col" style={{ zIndex: 1 }}>
              {isISPRunning ? (
                <button className="btn btn-primary  w-100 fs-5" disabled>
                  Running <span className="animated-dots"></span>
                </button>
              ) : (
                <button
                  className="btn btn-outline-primary  w-100 fs-5"
                  onClick={runISP}
                >
                  Run
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
});
export default ISP;
