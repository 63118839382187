import _ from "lodash";
import { IconMoodSad } from "@tabler/icons-react";

export const parseTimelogs= (obj) => {
  var timelogs = [];
  if (!_.isEmpty(obj.data)) {
    obj.data.forEach(function (el) {
        if (_.isObject(el)) {
            timelogs.push(el);
        }
    });
  }
  return timelogs;
};

export const parseTimesheet = (obj) => {
  var timesheet = [];
  if (!_.isEmpty(obj.data) && !_.isEmpty(obj.data.timesheet)) {
    obj.data.timesheet.forEach(function (el) {
      if (_.isObject(el)) {
          timesheet.push(el);
      }
  });
  }
  return timesheet;
};

export const stringToTime= (str) => {
  var date = new Date(str);
  var hours = date.getHours();
  hours = hours % 12;
  hours = hours ? hours : 12;
  return hours + ':' + (date.getMinutes() < 10 ? '0': '') + date.getMinutes() + ' ' + (date.getHours() >= 12 ? 'PM' : 'AM');
}

export const NoDataComponent = () => {
  return (
    <div className="empty">
      <div className="empty-icon">
        <IconMoodSad />
      </div>
      <p className="empty-title text-muted">No records found</p>
      <p className="empty-subtitle text-muted">
        Try adjusting your search or filter to find what you're looking for.
      </p>
      <div className="empty-action"></div>
    </div>
  );
};