import axios from "axios";

const api = axios.create({
  baseURL: process.env.REACT_APP_OM_API_URL,
});

// Function to set the access token
const setAccessToken = (accessToken) => {
  if (accessToken) {
    api.defaults.headers.common["Authorization"] = `Bearer ${accessToken}`;
  } else {
    delete api.defaults.headers.common["Authorization"];
  }
};
const setSelfServiceToken = async (selfServiceToken) => {
  if (selfServiceToken) {
    api.defaults.headers.common["Authorization"] = `Bearer ${selfServiceToken}`;
  } else {
    delete api.defaults.headers.common["Authorization"];
  }
};

export { api, setAccessToken, setSelfServiceToken };
