import React, { useState, useEffect, useMemo } from "react";
import DataTable from "react-data-table-component";
import { useSearchParams } from "react-router-dom";
import { tableCustomStyles } from "../style";
import { getAvatarInitials } from "pages/officeMonitoring/utils";
import { IconUsers, IconMoodSad } from "@tabler/icons-react";
import { TableSkeleton } from "components/ui/skeleton";

const FormatName = ({ data }) => {
  return (
    <div className="d-flex align-items-center">
      <span className="avatar avatar-sm me-2">{getAvatarInitials(data)}</span>
      {data}
    </div>
  );
};
const FormatTeamMembersCount = ({ data }) => {
  return (
    <React.Fragment>
      <IconUsers />
      <span className="ps-2">{data}</span>
    </React.Fragment>
  );
};

const FilterComponent = ({ filterText, onFilter, onClear }) => (
  <>
    <div className="ms-auto">
      Search:
      <div className="ms-2 d-inline-block">
        <div className="input-group input-group-sm">
          <input
            id="search"
            type="text"
            className="form-control form-control-sm"
            placeholder="Search "
            aria-label="Search Input"
            value={filterText}
            onChange={onFilter}
          />
          <button className="input-group-text btn" onClick={onClear}>
            x
          </button>
        </div>
      </div>
    </div>
  </>
);
const FormatTeamComplianceRate = ({ data }) => {
  var progressRate = parseInt(data);

  return (
    <div className="d-flex align-items-center">
      <div className="pe-3" style={{ overflow: "unset" }}>
        {" "}
        {progressRate}
      </div>
      <div className="progress progress-xs">
        <div
          className="progress-bar bg-primary"
          style={{ width: progressRate + "%" }}
        ></div>
      </div>
    </div>
  );
};
const NoDataComponent = () => {
  return (
    <div className="empty">
      <div className="empty-icon">
        <IconMoodSad />
      </div>
      <p className="empty-title text-muted">No records found</p>
      <p className="empty-subtitle text-muted">
        Try adjusting your search or filter to find what you're looking for.
      </p>
      <div className="empty-action"></div>
    </div>
  );
};

const sortByCompliance = (rowA, rowB) => {
  const a = parseInt(rowA.teamComplianceRate);
  const b = parseInt(rowB.teamComplianceRate);

  if (a > b) {
    return 1;
  }

  if (b > a) {
    return -1;
  }

  return 0;
};
const sortByTeamCount = (rowA, rowB) => {
  const a = parseInt(rowA.teamCount);
  const b = parseInt(rowB.teamCount);

  if (a > b) {
    return 1;
  }

  if (b > a) {
    return -1;
  }

  return 0;
};
const sortBySupervisor = (rowA, rowB) => {
  const a = rowA.name.toLowerCase();
  const b = rowB.name.toLowerCase();

  if (a > b) {
    return 1;
  }

  if (b > a) {
    return -1;
  }

  return 0;
};
export const ManagersTable = ({ data, isLoading }) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const [rows, setRows] = useState([]);

  const [filterText, setFilterText] = useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const filteredItems = rows.filter(
    (item) =>
      (item.name &&
        item.name.toLowerCase().includes(filterText.toLowerCase())) ||
      (item.teamCount &&
        parseInt(item.teamCount)
          .toString()
          .includes(filterText.toLowerCase())) ||
      (item.teamComplianceRate &&
        parseInt(item.teamComplianceRate)
          .toString()
          .includes(filterText.toLowerCase()))
  );

  const handleClear = () => {
    if (filterText) {
      setResetPaginationToggle(!resetPaginationToggle);
      setFilterText("");
    }
  };
  const setQueryParams = (email) => {
    searchParams.set("user", email);
    setSearchParams(searchParams);
  };

  const subHeaderComponentMemo = useMemo(() => {
    return (
      <FilterComponent
        onFilter={(e) => setFilterText(e.target.value)}
        onClear={handleClear}
        filterText={filterText}
      />
    );
  }, [filterText, resetPaginationToggle]);
  const paginationComponentOptions = {
    selectAllRowsItem: true,
  };
  const columns = [
    {
      name: "Name",
      selector: (row) => <FormatName data={row.name} />,
      sortable: true,
      sortFunction: sortBySupervisor,
    },
    {
      name: "Total No. of Members",
      selector: (row) => <FormatTeamMembersCount data={row.teamCount} />,
      sortable: true,
      sortFunction: sortByTeamCount,
    },
    {
      name: "Team's Office Attendance Rate (%)",
      grow: 2,
      selector: (row) => (
        <FormatTeamComplianceRate data={row.teamComplianceRate} />
      ),
      style: {
        display: "grid",
      },
      sortable: true,
      sortFunction: sortByCompliance,
    },
    {
      name: "",
      center: true,

      selector: (row) => (
        <button
          className="btn btn-outline-primary btn-sm"
          onClick={() => {
            handleClear();
            setQueryParams(row.email);
          }}
        >
          <span>View Dashboard</span>
        </button>
      ),
      style: {
        display: "grid",
      },
      sortable: true,
    },
  ];

  useEffect(() => {
    setRows(data);
  }, [data]);
  return (
    <div className="card">
      {isLoading ? (
        <TableSkeleton />
      ) : (
        <React.Fragment>
          <div className="card-header">
            <h3 className="card-title">Managers under your Team</h3>
          </div>
          <div className="table-responsive">
            <DataTable
              columns={columns}
              data={filteredItems}
              pagination
              paginationComponentOptions={paginationComponentOptions}
              customStyles={tableCustomStyles}
              subHeader
              subHeaderComponent={subHeaderComponentMemo}
              noDataComponent={<NoDataComponent />}
            />
          </div>
        </React.Fragment>
      )}
    </div>
  );
};
