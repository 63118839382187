import { createSlice } from "@reduxjs/toolkit";

export const activeUserSlice = createSlice({
  name: "activeUser",
  initialState: { value: {} },
  reducers: {
    updateActiveUser: (state, action) => {
      state.value = action.payload;
    },
  },
});

export const { updateActiveUser } = activeUserSlice.actions;

export default activeUserSlice.reducer;
