import { ALL_RELEASE_NOTES, CURRENT_RELEASE_NOTES } from "constants/global";

const Footer = () => {
  return (
    <footer className="footer footer-transparent d-print-none">
      <div className="container-xl">
        <div className="row text-center align-items-center flex-row-reverse">
          <div className="col-12 col-lg-auto mt-3 mt-lg-0">
            <ul className="list-inline list-inline-dots mb-0">
              <li className="list-inline-item">
                Copyright &copy; {new Date().getFullYear()}{" "}
                <a href="." className="link-secondary">
                  Trend Micro Inc
                </a>
                . All rights reserved.
              </li>
              <li className="list-inline-item">
                <a
                  href={CURRENT_RELEASE_NOTES}
                  target="_blank"
                  className="link-secondary"
                  rel="noopener"
                >
                  v1.11.0
                </a>
              </li>
              <li className="list-inline-item">
                <a
                  href={ALL_RELEASE_NOTES}
                  target="_blank"
                  className="link-secondary"
                  rel="noopener"
                >
                  All Release Notes
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
