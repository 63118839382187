import { CustomLink } from "./utils";
import {
  IconCalendarTime,
  IconDashboard,
  IconChartBar,
} from "@tabler/icons-react";
const NavigationBar = () => {
  return (
    <div className="navbar-expand-md">
      <div className="collapse navbar-collapse" id="navbar-menu">
        <div className="navbar navbar-light">
          <div className="container-xl">
            <ul className="navbar-nav">
              <CustomLink to="/">
                <span className="nav-link-icon d-md-none d-lg-inline-block">
                  <IconChartBar />
                </span>
                <span className="nav-link-title">Office Monitoring</span>
              </CustomLink>
              <CustomLink to="/timelog">
                <span className="nav-link-icon d-md-none d-lg-inline-block">
                  <IconCalendarTime />
                </span>
                <span className="nav-link-title">Timelog</span>
              </CustomLink>
              <CustomLink to="/selfhelpdesk">
                <span className="nav-link-icon d-md-none d-lg-inline-block">
                  <IconDashboard />
                </span>
                <span className="nav-link-title">Self Help Desk</span>
              </CustomLink>
            </ul>
            <div className="my-2 my-md-0 flex-grow-1 flex-md-grow-0 order-first order-md-last">
              {/* <form action="." method="get">
                <div className="input-icon">
                  <span className="input-icon-addon">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="icon"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      strokeWidth="2"
                      stroke="currentColor"
                      fill="none"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    >
                      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                      <circle cx="10" cy="10" r="7" />
                      <line x1="21" y1="21" x2="15" y2="15" />
                    </svg>
                  </span>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Search…"
                    aria-label="Search in website"
                  />
                </div>
              </form> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NavigationBar;
