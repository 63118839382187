/* QUEUE */
export function Queue() {
  this.elements = [];
}
// add an element at the back of the queue
Queue.prototype.enqueue = function (e) {
  this.elements.push(e);
};
// remove an element from the front of the queue
Queue.prototype.dequeue = function () {
  return this.elements.shift();
};
// check if the queue is empty
Queue.prototype.isEmpty = function () {
  return this.elements.length == 0;
};
// get the element at the front of the queue
Queue.prototype.peek = function () {
  return !this.isEmpty() ? this.elements[0] : undefined;
};
Queue.prototype.length = function () {
  return this.elements.length;
};
