import React from "react";
import DataTable from "react-data-table-component";
import { useEffect, useState } from "react";
import { format } from "date-fns";
import { tableCustomStyles } from "../../../style";
import { TableSkeleton } from "components/ui/skeleton";
import { MonthSelector } from "../../monthSelector";
import { NoDataComponent } from "../utils";
import { IconDoor } from '@tabler/icons-react';

export const TimesheetTable = ({ data = [], setDateSelected, dateSelected, isLoading }) => {
  const [rows, setRows] = useState([]);
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);

  const subHeaderComponentMemo = React.useMemo(() => {
    return (
      <>
        <div className="col-md-6 text-start">
        </div>
        <div className="col-md-6 text-end">
          <MonthSelector
            setDateSelected={setDateSelected}
            dateSelected={dateSelected}
          />
        </div>
      </>
    );
  }, [resetPaginationToggle, data]);

  const paginationComponentOptions = {
    selectAllRowsItem: true,
  };

  const columns = [
    {
      name: "Date",
      selector: (row) => row.date,
      grow: 2,
      sortable: true,
      cell: row => `${format(new Date(row.date), 'MM/dd/yyyy')}, ${format(new Date(row.date), 'EEE')}`
    },
    {
      name: "Schedule",
      selector: (row) => row.scheduleStart,
      grow: 2,
      sortable: true,
      cell: row => 
        row.IsOnLeave ? "On Leave" :
        !row.isOnSchedule ? row.type :
        `${format(new Date(row.scheduleStart), 'MM/dd hh:mm a')} - ${format(new Date(row.scheduleEnd), 'MM/dd hh:mm a')}`
        
    },
    {
      name: "Time In",
      selector: (row) => row.timeIn,
      grow: 2,
      sortable: true,
      cell: row => {
        if (row.timeIn) {
          let timeIn = `${format(new Date(row.timeIn), 'MM/dd hh:mm a')}`;
          if (row.timeInIsLog) {
            return (<DoorLogComponent doorLog={timeIn}></DoorLogComponent>);
          }
          else {
            return timeIn;
          }
        }
        return "";
      }
    },
    {
      name: "Time Out",
      selector: (row) => row.timeOut,
      grow: 2,
      sortable: true,
      cell: row => {
        if (row.timeOut) {
          let timeOut = `${format(new Date(row.timeOut), 'MM/dd hh:mm a')}`;
          if (row.timeOutIsLog) {
            return (<DoorLogComponent doorLog={timeOut}></DoorLogComponent>);
          }
          else {
            return timeOut;
          }
        }
        return "";
      }
    }
  ];

  const DoorLogComponent = (props) => {
    const { doorLog } = props;
    return (
      <span>{doorLog} <IconDoor /></span>
    )
  }

  useEffect(() => { setRows(data); }, [data]);
  return (
    <div className="card">
      {isLoading ? (
        <TableSkeleton />
      ) : (
        <React.Fragment>
          {" "}
          <div className="card-header">
            <h3 className="card-title">Timesheet</h3>
          </div>
          <div className="table-responsive">
            <DataTable
              columns={columns}
              data={rows}
              pagination
              paginationComponentOptions={paginationComponentOptions}
              customStyles={tableCustomStyles}
              subHeader
              subHeaderComponent={subHeaderComponentMemo}
              noDataComponent={<NoDataComponent />}
            />
          </div>
        </React.Fragment>
      )}
    </div>
  );
};
