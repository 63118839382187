import Chart from "react-apexcharts";
import React, { useEffect, useState } from "react";
import { IconUsers } from "@tabler/icons-react";
import _ from "lodash";
import { PieChartSkeleton } from "components/ui/skeleton";

export const ComplianceRateChart = ({ data, isLoading }) => {
  const [series, setSeries] = useState([0, 0]);
  const [overallComplianceRate, setOverallComplianceRate] = useState(0);
  const [totalMembers, setTotalMembers] = useState(0);
  var options = {
    chart: {
      type: "donut",
      fontFamily: "inherit",
      height: 240,
      sparkline: {
        enabled: true,
      },
      animations: {
        enabled: false,
      },
    },
    fill: {
      opacity: 1,
    },
    series: series,
    labels: ["2 or more days onsite", "Less than 2 days onsite"],
    grid: {
      strokeDashArray: 4,
    },
    colors: ["#206bc4", "#79a6dc", "#d2e1f3", "#e9ecf1"],
    legend: {
      show: true,
      position: "bottom",
      offsetY: 12,
      markers: {
        width: 10,
        height: 10,
        radius: 100,
      },
      itemMargin: {
        horizontal: 8,
        vertical: 8,
      },
    },
    tooltip: {
      fillSeriesColor: false,
    },
  };

  useEffect(() => {
    if (data != null && !_.isEmpty(data)) {
      setTotalMembers(data.members);
      setSeries([data.compliant, data.nonCompliant]);
      setOverallComplianceRate(
        parseInt(data.members) > 0
          ? parseFloat((parseInt(data.compliant) / data.members) * 100).toFixed(
              2
            )
          : 0
      );
    }
  }, [data]);
  return (
    <div className="card">
      <div className="card-body">
        {!isLoading ? (
          <React.Fragment>
            <div className="d-flex align-items-center">
              <div className="subheader">Overall Office Attendance Rate</div>
            </div>
            <div className="mb-3 d-flex justify-content-between align-items-center">
              <h1>{overallComplianceRate}%</h1>{" "}
              <div>
                <IconUsers />
                {totalMembers}
              </div>
            </div>
            <div className="d-flex mb-2"></div>
            <div id="pie-compliance-rate"></div>
            <Chart
              options={options}
              series={options.series}
              type={options.chart.type}
              height={options.chart.height}
            />
          </React.Fragment>
        ) : (
          <PieChartSkeleton />
        )}
      </div>
    </div>
  );
};
