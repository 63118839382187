import { useEffect, useState } from "react";
import Select, { components } from "react-select";
import { useSelector } from "react-redux";
import { isBefore } from "date-fns";
import { COMPLIANCE_OVERRIDE_TYPES } from "constants/officemonitoring";
import { useNavigate } from "react-router-dom";
import { Notification, useToaster } from "rsuite";
import { FormProvider, useForm } from "react-hook-form";
/* API Methods */
import { postComplianceOverride } from "pages/officeMonitoring/services/api";
/* Utilities */
import { IconOption, IconSingleValue } from "./utils";

const eventTypes = [
  {
    value: "Authorized WFH",
  },
  {
    value: "Pending Leave Filing"
  },
  {
    value: "Invalidate",
  }
];

export const CreateComplianceOverrideModal = ({ author, renderData }) => {
  const [formData, setFormData] = useState({
    employees: [],
    startDate: "",
    endDate: "",
    remarks: "",
    type: null,
    author: null,
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    values: formData,
    resetOptions: {
      keepDirtyValues: true, // keep dirty fields unchanged, but update defaultValues
    },
  });
  const navigate = useNavigate();
  const toaster = useToaster();

  const [selectedType, setSelectedType] = useState(null);

  const [isLoading, setIsLoading] = useState(false);

  const overrideFormData = useSelector((state) => state.overrideFormData.value);

  const onSubmit = handleSubmit((data) => {
    setIsLoading(true);
    const modalElement = document.getElementById("modal-create-override");
    const modal = window.bootstrap.Modal.getInstance(modalElement);

    const offCanvasElement = document.getElementById("offcanvasEnd");
    const offcanvas = window.bootstrap.Offcanvas.getInstance(offCanvasElement);

    postComplianceOverride({
      employees: data.employees,
      startDate: data.startDate,
      endDate: data.endDate,
      remarks: data.remarks,
      type: data.type,
      author,
    })
      .then((response) => {
        if (!(response.data?.length ?? 0)) {
          toaster.push(
            <Notification type="error" header="Failed" closable>
              Conflicting or overlapping override requests detected. Please
              ensure that the requested overrides do not conflict with existing
              overrides.
            </Notification>,
            {
              placement: "topEnd",
              duration: 5000,
            }
          );
        } else {
          toaster.push(
            <Notification
              type="success"
              header="Submitted Successfully"
              closable
            >
              Your office attendance override has been applied
            </Notification>,
            {
              placement: "topEnd",
              duration: 5000,
            }
          );
          renderData();
        }
      })
      .catch((error) => {
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx

          switch (error.response.status) {
            case 401: {
              toaster.push(
                <Notification type="error" header="Unauthorized" closable>
                  The operation failed. You don't have permission to perform
                  this action.
                </Notification>,
                {
                  placement: "topEnd",
                  duration: 5000,
                }
              );
              break;
            }
            case 400: {
              toaster.push(
                <Notification type="error" header="Bad Request" closable>
                  {/* We have received an invalid request. Please ensure that the
                  requested overrides do not conflict with existing overrides. */}
                  {error.response.data}
                </Notification>,
                {
                  placement: "topEnd",
                  duration: 5000,
                }
              );
              break;
            }
            default:
              navigate(`/${error.response.status}`);
          }
        } else {
          toaster.push(
            <Notification type="error" header="Something went wrong" closable>
              The operation failed. Please try again later or contact the
              administrator of this page.
            </Notification>,
            {
              placement: "topEnd",
              duration: 5000,
            }
          );
        }
      })
      .finally(() => {
        setIsLoading(false);
        setSelectedType(null);
        modal.hide();
        offcanvas.hide();
      });
  });

  useEffect(() => {
    setFormData(overrideFormData);
    setSelectedType(null);
  }, [overrideFormData]);

  return (
    <div
      className="modal modal-blur fade"
      id="modal-create-override"
      tabIndex="-1"
      role="dialog"
      aria-modal="true"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
    >
      <div
        className="modal-dialog modal-md modal-dialog-centered"
        role="document"
      >
        <div className="modal-content">
          {isLoading && (
            <div className="progress">
              <div className="progress-bar progress-bar-indeterminate bg-primary"></div>
            </div>
          )}
          <div className="modal-header">
            <h5 className="modal-title">Create Onsite Attendance Override</h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body">
            <FormProvider>
              <form onSubmit={(e) => e.preventDefault()}>
                <div className="row">
                  <div className="col-lg-12">
                    <div className="mb-3">
                      <label className="form-label required">
                        Override Type
                      </label>
                      <Select
                        {...register("type", { required: true })}
                        options={eventTypes}
                        isSearchable={true}
                        components={{
                          Option: IconOption,
                          SingleValue: IconSingleValue,
                        }}
                        value={selectedType}
                        onChange={(e) => {
                          setSelectedType(e);
                          setFormData({
                            ...formData,
                            type: e.value,
                          });
                        }}
                        className={errors.type ? "is-invalid" : ""}
                      />
                      {errors.type?.type === "required" && (
                        <div className="invalid-feedback">
                          This field is required
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div className="mb-3">
                      <label className="form-label required">Who</label>
                      {/* <Multiselect
                    options={formData.users} // Options to display in the dropdown
                    selectedValues={formData.users} // Preselected value to persist in dropdown
                    onSelect={() => {}} // Function will trigger on select event
                    onRemove={() => {}} // Function will trigger on remove event
                    displayValue="email" // Property name to display in the dropdown options
                    isObject={false}
                    placeholder="Email"
                  /> */}
                      <input
                        {...register("employees")}
                        type="text"
                        className={
                          errors.employees
                            ? "form-control is-invalid"
                            : "form-control"
                        }
                        name="example-text-input"
                        placeholder="Employee Name"
                        value={formData.employees[0] || ""}
                        disabled={true}
                      />
                      {errors.type?.type === "required" && (
                        <div className="invalid-feedback">
                          This field is required
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="mb-3">
                      <label className="form-label required">Start Date</label>
                      <input
                        {...register("startDate", { required: true })}
                        type="date"
                        className={
                          errors.startDate
                            ? "form-control is-invalid"
                            : "form-control"
                        }
                        value={formData.startDate}
                        onChange={(e) => {
                          if (
                            isBefore(
                              new Date(formData.endDate),
                              new Date(e.target.value)
                            )
                          ) {
                            console.log(formData.endDate);
                            setFormData({
                              ...formData,
                              startDate: e.target.value,
                              endDate: e.target.value,
                            });
                          } else {
                            setFormData({
                              ...formData,
                              startDate: e.target.value,
                            });
                          }
                        }}
                      />
                      {errors.startDate?.type === "required" && (
                        <div className="invalid-feedback">
                          This field is required
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="mb-3">
                      <label className="form-label required">End Date</label>
                      <input
                        {...register("endDate", { required: true })}
                        type="date"
                        className={
                          errors.endDate
                            ? "form-control is-invalid"
                            : "form-control"
                        }
                        value={formData.endDate}
                        onChange={(e) => {
                          if (
                            isBefore(
                              new Date(e.target.value),
                              new Date(formData.startDate)
                            )
                          ) {
                            alert("Please select a valid end date.");
                          } else {
                            setFormData({
                              ...formData,
                              endDate: e.target.value,
                            });
                          }
                        }}
                      />
                      {errors.endDate?.type === "required" && (
                        <div className="invalid-feedback">
                          This field is required
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div>
                      <label className="form-label">
                        Remarks{" "}
                        <span className="small text-muted">(Required)</span>
                      </label>
                      <textarea
                        {...register("remarks", { required: true, maxLength: 300 })}
                        className={
                          errors.remarks
                            ? "form-control is-invalid"
                            : "form-control"
                        }
                        rows="3"
                        value={formData.remarks}
                        onChange={(e) =>
                          setFormData({ ...formData, remarks: e.target.value })
                        }
                      ></textarea>
                      {errors.remarks?.type === "maxLength" && (
                        <div className="invalid-feedback">
                          Max allowed characters (100) reached
                        </div>
                      )}
                      {errors.remarks?.type === "required" && (
                        <div className="invalid-feedback">
                          This field is required
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </form>
            </FormProvider>
          </div>

          <div className="modal-footer">
            <button
              className="btn btn-link link-secondary"
              data-bs-dismiss="modal"
            >
              Cancel
            </button>
            <button
              className="btn btn-primary "
              onClick={onSubmit}
              disabled={isLoading}
            >
              {isLoading ? (
                <>
                  Loading <span className="animated-dots"></span>
                </>
              ) : (
                "Save Changes"
              )}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
