import React from "react";
import DataTable from "react-data-table-component";
import { useEffect, useState } from "react";
import { tableCustomStyles } from "../style";
import { handleTeamTableRowClick } from "../utils";
import { TableSkeleton } from "components/ui/skeleton";
import { IconMoodSad, IconDownload } from "@tabler/icons-react";
const FormatName = ({ name, email, isManager, complianceRate, regTemp }) => {
  const compliant = 100;

  return (
    <React.Fragment>
      <span
        className={
          parseInt(complianceRate) === compliant
            ? "badge bg-success me-3"
            : complianceRate > 0
            ? "badge bg-yellow me-3"
            : regTemp !== "Regular"
            ? "badge bg-gray me-3"
            : "badge bg-danger me-3 "
        }
      ></span>
      {isManager ? (
        <>
          {name}
          <span className="ms-2 badge bg-info-lt">Manager</span>
        </>
      ) : (
        name
      )}
    </React.Fragment>
  );
};

const FilterComponent = ({ filterText, onFilter, onClear }) => (
  <>
    <div className="ms-auto">
      Search:
      <div className="ms-2 d-inline-block">
        <div className="input-group input-group-sm">
          <input
            id="search"
            type="text"
            className="form-control form-control-sm"
            placeholder="Search "
            aria-label="Search Input"
            value={filterText}
            onChange={onFilter}
          />
          <button className="input-group-text btn" onClick={onClear}>
            x
          </button>
        </div>
      </div>
    </div>
  </>
);
const NoDataComponent = () => {
  return (
    <div className="empty">
      <div className="empty-icon">
        <IconMoodSad />
      </div>
      <p className="empty-title text-muted">No records found</p>
      <p className="empty-subtitle text-muted">
        Try adjusting your search or filter to find what you're looking for.
      </p>
      <div className="empty-action"></div>
    </div>
  );
};
const sortByCompliance = (rowA, rowB) => {
  const a = parseInt(rowA.complianceRate);
  const b = parseInt(rowB.complianceRate);

  if (a > b) {
    return 1;
  }

  if (b > a) {
    return -1;
  }

  return 0;
};
function convertArrayOfObjectsToCSV(array) {
  let result;

  const columnDelimiter = ",";
  const lineDelimiter = "\n";
  const keys = Object.keys(array[0]);

  result = "";
  result += keys.join(columnDelimiter);
  result += lineDelimiter;

  array.forEach((item) => {
    let ctr = 0;
    keys.forEach((key) => {
      if (ctr > 0) result += columnDelimiter;

      result += item[key];

      ctr++;
    });
    result += lineDelimiter;
  });

  return result;
}
function filterColumnsToExport(array) {
  return array.map(
    ({
      emplId: PSID,
      name: Name,
      email: Email,
      dept: Department,
      country: Country,
      isManager: IsManager,
      supervisor: Manager,
      supervisorId: ManagerId,
      regTemp: RegTemp,
      complianceRate: ComplianceRate,
    }) => ({
      PSID,
      Name,
      Email,
      Department,
      Country,
      IsManager,
      Manager,
      ManagerId,
      RegTemp,
      ComplianceRate,
    })
  );
}
// Blatant "inspiration" from https://codepen.io/Jacqueline34/pen/pyVoWr
function downloadCSV(array) {
  const link = document.createElement("a");
  const filteredData = filterColumnsToExport(array);

  let csv = convertArrayOfObjectsToCSV(filteredData);
  if (csv == null) return;

  const filename = "r2w-all-members-table.csv";

  if (!csv.match(/^data:text\/csv/i)) {
    csv = `data:text/csv;charset=utf-8,${csv}`;
  }

  link.setAttribute("href", encodeURI(csv));
  link.setAttribute("download", filename);
  link.click();
}

export const AllMembersTable = ({ data = [], setSelectedUser, isLoading }) => {
  const [rows, setRows] = useState([]);
  const [filterText, setFilterText] = useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const filteredItems = rows.filter(
    (item) =>
      (item.name &&
        item.name.toLowerCase().includes(filterText.toLowerCase())) ||
      (item.country &&
        item.country.toLowerCase().includes(filterText.toLowerCase())) ||
      (item.supervisor &&
        item.supervisor.toLowerCase().includes(filterText.toLowerCase())) ||
      (item.dept &&
        item.dept.toLowerCase().includes(filterText.toLowerCase())) ||
      (item.complianceRate &&
        parseInt(item.complianceRate)
          .toString()
          .includes(filterText.toLowerCase()))
  );

  const handleClear = () => {
    if (filterText) {
      setResetPaginationToggle(!resetPaginationToggle);
      setFilterText("");
    }
  };
  const subHeaderComponentMemo = React.useMemo(() => {
    return (
      <>
        <div className="col-md-6 text-start">
          <button
            className="btn btn-sm btn-outline-primary"
            onClick={() => downloadCSV(data)}
          >
            <IconDownload className="icon" />
            Export Table
          </button>
        </div>
        <div className="col-md-6 text-end">
          <FilterComponent
            onFilter={(e) => setFilterText(e.target.value)}
            onClear={handleClear}
            filterText={filterText}
          />
        </div>
      </>
    );
  }, [filterText, resetPaginationToggle, data]);

  const paginationComponentOptions = {
    selectAllRowsItem: true,
  };

  const columns = [
    {
      name: "Name",
      selector: (row) => row.name,
      sortable: true,
      grow: 2,
      cell: (row) => (
        <FormatName
          name={row.name}
          email={row.email}
          isManager={row.isManager}
          complianceRate={row.complianceRate}
          regTemp={row.regTemp}
        />
      ),
    },
    {
      name: "Country",
      selector: (row) => row.country,
      sortable: true,
    },
    {
      name: "Manager",
      selector: (row) => row.supervisor,
      grow: 2,
      sortable: true,
    },
    {
      name: "Department",
      selector: (row) => row.dept,
      grow: 2,
      sortable: true,
    },
    {
      name: "Office Attendance Rate (%)",
      selector: (row) =>
        (row.isManager && row.country !== "PHL") || row.regTemp !== "Regular" ? (
          <span>--</span>
        ) : (
          parseInt(row.complianceRate)
        ),
      grow: 1.7,
      sortable: true,
      center: true,
      sortFunction: sortByCompliance,
    },
    {
      name: "",
      center: true,
      selector: (row) =>
        (row.isManager && row.country !== "PHL") ? (
          <span>--</span>
        ) : (
          <a
            data-bs-toggle="offcanvas"
            href="#offcanvasEnd"
            role="button"
            aria-controls="offcanvasEnd"
            className="btn btn-outline-primary btn-sm"
            onClick={() => {
              const filteredData = handleTeamTableRowClick(data, row.emplId);
              setSelectedUser(filteredData);
            }}
          >
            <span>Show Details</span>
          </a>
        ),
    },
  ];

  useEffect(() => {
    if (data != null && data.length > 0) {
      setRows(data);
    }
  }, [data]);

  return (
    <div className="card">
      {isLoading ? (
        <TableSkeleton />
      ) : (
        <React.Fragment>
          {" "}
          <div className="card-header">
            <h3 className="card-title">All Members</h3>
          </div>
          <div className="table-responsive">
            <DataTable
              columns={columns}
              data={filteredItems}
              pagination
              paginationComponentOptions={paginationComponentOptions}
              customStyles={tableCustomStyles}
              subHeader
              subHeaderComponent={subHeaderComponentMemo}
              noDataComponent={<NoDataComponent />}
            />
          </div>
        </React.Fragment>
      )}
    </div>
  );
};
