import { Queue } from "./queue";
import { Ping } from "./ping";

export function checkForMaxConsecutive(arr) {
  let matches = 0;

  for (var i = 0; i < arr.elements.length - 1; i++) {
    if (arr.elements[i] == arr.elements[i + 1]) {
      matches++;
    } else {
      matches = 0;
    }
    if (matches == 2) {
      return true;
    }
  }

  return false;
}
export function pingVPN(address, imageSrc) {
  let _ping = new Ping({ favicon: imageSrc });
  let queue = new Queue();

  let times = [],
    success = 0,
    failed = 0,
    ctr = 0,
    output;
  return new Promise((resolve, reject) => {
    let result = setInterval(() => {
      _ping.ping(address, function (err, data) {
        if (err || data >= 1500) {
          output = "Request timeout for ping_seq " + ctr;
          failed++;

          queue.enqueue(0);
        } else {
          output =
            "Transmission to " +
            address +
            ": ping_seq=" +
            ctr +
            " time=" +
            data +
            " ms";
          times.push(data);
          success++;
          queue.enqueue(1);
        }
        console.log(output);

        if (queue.length() == 4) {
          console.log(queue);
          let hasConsecutive = checkForMaxConsecutive(queue);
          console.log(hasConsecutive);
          if (hasConsecutive == true) {
            let min = Math.min(...times);
            let max = Math.max(...times);
            let avg = (times.reduce((a, b) => a + b, 0) / times.length).toFixed(
              0
            );

            failed = failed > 4 ? 0 : failed;
            let loss = (failed / 4) * 100;
            let received = 4 - failed;
            clearTimeout(result);
            resolve({
              sent: 4,
              received: received,
              loss: loss,
              rtt: avg,
            });
          } else {
            queue.dequeue();
          }
        }
      });
    }, 1500);
  });
}
