import { configureStore } from "@reduxjs/toolkit";
import {
  complianceRecordReducer,
  dateRangeSelectedReducer,
  activeUserReducer,
  overrideFormDataReducer,
} from "../../reducers";
import { monthSelectedReducer } from "pages/timelog/state/reducers";

export const store = configureStore({
  reducer: {
    complianceRecord: complianceRecordReducer,
    dateRangeSelected: dateRangeSelectedReducer,
    activeUser: activeUserReducer,
    overrideFormData: overrideFormDataReducer,
    monthSelected: monthSelectedReducer
  },
});
