/* eslint-disable no-undef */
/* eslint-disable no-unused-expressions */
/*!
 * Tabler v1.0.0-beta5 (https://tabler.io)
 * @version 1.0.0-beta5
 * @link https://tabler.io
 * Copyright 2018-2022 The Tabler Authors
 * Copyright 2018-2022 codecalm.net Paweł Kuna
 * Licensed under MIT (https://github.com/tabler/tabler/blob/master/LICENSE)
 */
!(function (e) {
  "function" == typeof define && define.amd ? define(e) : e();
})(function () {
  "use strict";
  function e(e, r) {
    return (
      (function (e) {
        if (Array.isArray(e)) return e;
      })(e) ||
      (function (e, t) {
        var r =
          null == e
            ? null
            : ("undefined" != typeof Symbol && e[Symbol.iterator]) ||
              e["@@iterator"];
        if (null == r) return;
        var n,
          o,
          a = [],
          l = !0,
          c = !1;
        try {
          for (
            r = r.call(e);
            !(l = (n = r.next()).done) &&
            (a.push(n.value), !t || a.length !== t);
            l = !0
          );
        } catch (e) {
          (c = !0), (o = e);
        } finally {
          try {
            l || null == r.return || r.return();
          } finally {
            if (c) throw o;
          }
        }
        return a;
      })(e, r) ||
      (function (e, r) {
        if (!e) return;
        if ("string" == typeof e) return t(e, r);
        var n = Object.prototype.toString.call(e).slice(8, -1);
        "Object" === n && e.constructor && (n = e.constructor.name);
        if ("Map" === n || "Set" === n) return Array.from(e);
        if (
          "Arguments" === n ||
          /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)
        )
          return t(e, r);
      })(e, r) ||
      (function () {
        throw new TypeError(
          "Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."
        );
      })()
    );
  }
  function t(e, t) {
    (null == t || t > e.length) && (t = e.length);
    for (var r = 0, n = new Array(t); r < t; r++) n[r] = e[r];
    return n;
  }
  for (
    var r = {
        theme: { localStorage: "tablerTheme", default: "light" },
        "menu-position": { localStorage: "tablerMenuPosition", default: "top" },
        "menu-behavior": {
          localStorage: "tablerMenuBehavior",
          default: "sticky",
        },
        "container-layout": {
          localStorage: "tablerContainerLayout",
          default: "boxed",
        },
      },
      n = {},
      o = 0,
      a = Object.entries(r);
    o < a.length;
    o++
  ) {
    var l = e(a[o], 2),
      c = l[0],
      i = l[1];
    n[c] = localStorage.getItem(i.localStorage)
      ? localStorage.getItem(i.localStorage)
      : i.default;
  }
  var u = function () {
    document.body.classList.remove("theme-dark"),
      document.body.classList.remove("theme-light"),
      document.body.classList.add("theme-".concat(n.theme));
  };
  !(function () {
    for (
      var e = window.location.search.substring(1).split("&"), t = 0;
      t < e.length;
      t++
    ) {
      var o = e[t].split("="),
        a = o[0],
        l = o[1];
      r[a] && (localStorage.setItem(r[a].localStorage, l), (n[a] = l));
    }
  })(),
    u();
  var s = document.querySelector("#offcanvasSettings");
  s &&
    (s.addEventListener("submit", function (t) {
      t.preventDefault(),
        (function (t) {
          for (var o = 0, a = Object.entries(r); o < a.length; o++) {
            var l = e(a[o], 2),
              c = l[0],
              i = l[1],
              s = t.querySelector(
                '[name="settings-'.concat(c, '"]:checked')
              ).value;
            localStorage.setItem(i.localStorage, s), (n[c] = s);
          }
          u(),
            window.dispatchEvent(new Event("resize")),
            new bootstrap.Offcanvas(t).hide();
        })(s);
    }),
    (function (t) {
      for (var o = 0, a = Object.entries(r); o < a.length; o++) {
        var l = e(a[o], 2),
          c = l[0];
        l[1];
        var i = t.querySelector(
          '[name="settings-'.concat(c, '"][value="').concat(n[c], '"]')
        );
        i && (i.checked = !0);
      }
    })(s));
});
