import Chart from "react-apexcharts";
import { useEffect, useState } from "react";
import { BarChartSkeleton } from "components/ui/skeleton";
import React from "react";
export const EmployeesPerDayChart = ({ data = [], isLoading }) => {
  const [series, setSeries] = useState([]);
  const [labels, setLabels] = useState([]);

  var options = {
    chart: {
      type: "bar",
      fontFamily: "inherit",
      height: 240,
      parentHeightOffset: 0,
      toolbar: {
        show: false,
      },
      animations: {
        enabled: false,
      },
      stacked: true,
    },
    plotOptions: {
      bar: {
        columnWidth: "50%",
      },
    },
    dataLabels: {
      enabled: false,
    },
    fill: {
      opacity: 1,
    },
    series: series,
    grid: {
      padding: {
        top: -20,
        right: 0,
        left: -4,
        bottom: -4,
      },
      strokeDashArray: 4,
      xaxis: {
        lines: {
          show: true,
        },
      },
    },
    xaxis: {
      labels: {
        padding: 0,
      },
      tooltip: {
        enabled: false,
      },
      axisBorder: {
        show: false,
      },
      type: "datetime",
    },
    yaxis: {
      labels: {
        padding: 4,
      },
    },
    labels: labels,
    colors: ["#206bc4", "#79a6dc", "#bfe399"],
    legend: {
      show: true,
    },
  };

  useEffect(() => {
    let counts = [];
    let dates = [];
    data.forEach((element) => {
      counts.push(element.count);
      dates.push(element.date);
    });
    setSeries([
      {
        name: "Employee",
        data: counts,
      },
    ]);
    setLabels(dates);
  }, [data]);

  return (
    <div className="row row-cards">
      <div className="col-12">
        <div className="card">
          <div className="card-body">
            {isLoading ? (
              <BarChartSkeleton />
            ) : (
              <React.Fragment>
                <div className="subheader">Office Attendance</div>
                <h3 className="card-title">Employees Per Day</h3>
                <Chart
                  options={options}
                  series={options.series}
                  type={options.chart.type}
                  height={options.chart.height}
                />
              </React.Fragment>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
