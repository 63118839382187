import { useMsal } from "@azure/msal-react";
import { getAvatarInitials } from "pages/officeMonitoring/utils";
import {
  IconMoon,
  IconSun,
  IconSpeakerphone,
  IconBell,
} from "@tabler/icons-react";

import logo from "../../../assets/img/logo/tm-logo.png";

const Header = () => {
  const { accounts } = useMsal();
  const name = accounts[0].name;

  const avatar = name.includes("(")
    ? getAvatarInitials(name.split(" (")[0])
    : getAvatarInitials(name);
  return (
    <header className="navbar navbar-expand-md navbar-light d-print-none">
      <div className="container-xl">
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbar-menu"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <h1 className="navbar-brand navbar-brand-autodark d-none-navbar-horizontal pe-0 pe-md-3">
          <a href=".">
            <img
              src={logo}
              width="110"
              height="32"
              alt="Office Monitoring"
              className="navbar-brand-image"
            />
          </a>
        </h1>

        <div className="navbar-nav flex-row order-md-last">
          <div className="nav-item d-flex me-3">
            <div className="btn-list">
              <a
                href="https://ecentral.trendmicro.com.ph/iDeas/OfficeMonitoring"
                className="btn"
                target="_blank"
                rel="noreferrer"
              >
                <IconSpeakerphone className="icon" />
                Send Feedback
              </a>
            </div>
          </div>
          <button
            className=" nav-link px-0 hide-theme-dark bg-transparent"
            title="Enable dark mode"
            data-bs-toggle="tooltip"
            data-bs-placement="bottom"
            onClick={() => {
              const urlParams = new URLSearchParams(window.location.search);
              urlParams.set("theme", "dark");
              window.location.search = urlParams;
            }}
          >
            <IconMoon className="icon" />
          </button>
          <button
            className="nav-link px-0 hide-theme-light bg-transparent"
            title="Enable light mode"
            data-bs-toggle="tooltip"
            data-bs-placement="bottom"
            onClick={() => {
              const urlParams = new URLSearchParams(window.location.search);
              urlParams.set("theme", "light");
              window.location.search = urlParams;
            }}
          >
            <IconSun />
          </button>
          <div className="nav-item dropdown d-none d-md-flex me-3">
            <button
              className="bg-transparent nav-link px-0"
              data-bs-toggle="dropdown"
              tabIndex="-1"
              aria-label="Show notifications"
            >
              <IconBell className="icon" />
              {/* <span className="badge bg-red"></span> */}
            </button>
            <div className="dropdown-menu dropdown-menu-end dropdown-menu-card">
              <div className="card">
                <div className="card-body text-center">
                  No notifications available
                </div>
              </div>
            </div>
          </div>
          <div className="nav-item dropdown">
            <button
              className="bg-transparent nav-link d-flex lh-1 text-reset p-0"
              data-bs-toggle="dropdown"
              aria-label="Open user menu"
            >
              <span className="avatar avatar-sm">{avatar}</span>
              <div className="d-none d-xl-block ps-2">
                <div>{name}</div>
                {/* <div className="mt-1 small text-muted">Manager</div> */}
              </div>
            </button>
            {/* <div className="dropdown-menu dropdown-menu-end dropdown-menu-arrow">
              <a href="#" className="dropdown-item">
                Set status
              </a>
              <a href="#" className="dropdown-item">
                Profile and account
              </a>
              <a href="#" className="dropdown-item">
                Feedback
              </a>
              <div className="dropdown-divider"></div>
              <a href="#" className="dropdown-item">
                Settings
              </a>
              <a href="#" className="dropdown-item">
                Logout
              </a>
            </div> */}
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
