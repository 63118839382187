/*Third Party */
import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { IconCircleCheckFilled, IconCircleXFilled } from "@tabler/icons-react";
import { pingVPN } from "./utils";
import { useDispatch, useSelector } from "react-redux";
import { updateSelectedTests } from "pages/selfHelpDesk/state/reducers";
/*Local Features*/

/* API Methods */

/* Features-Utils */

/* Config */

/*UI Component*/

const VPN = forwardRef(({ id, serverName, serverUrl, resourceUrl }, ref) => {
  const dispatch = useDispatch();
  const [isVPNSelected, setIsVPNSelected] = useState(false);
  const [isVPNRunning, setVPNRunning] = useState(false);
  const [isPassed, setIsPassed] = useState(null);
  const [vpnResult, setVPNResult] = useState({
    sent: "No result yet",
    received: "No result yet",
    loss: "No result yet",
    rtt: "No result yet",
  });
  const selectedTests = useSelector((state) => state.selectedTests.value);
  useEffect(() => {
    const isSelected = selectedTests.find(
      (metric) => metric.id === id
    )?.isSelected;

    if (isSelected) {
      setIsVPNSelected(isSelected);
    }
  }, [selectedTests]);
  useEffect(() => {
    const updatedSelectedTests = selectedTests.map((test) => {
      if (test.id === id) {
        return { ...test, isSelected: !test.isSelected };
      }
      return test;
    });
    dispatch(updateSelectedTests(updatedSelectedTests));
  }, [isVPNSelected]);

  async function runVPN() {
    if (!isVPNRunning) {
      setVPNRunning(true);
      const result = await pingVPN(serverUrl, resourceUrl);
      setVPNResult(result);

      setVPNResult((prevData) => ({
        ...prevData,
        rtt: isNaN(result.rtt) ? "--" : `${result.rtt}ms`,
        loss: `${result.loss}%`,
      }));

      if (result.loss > 25) {
        setIsPassed(false);
      } else {
        setIsPassed(true);
      }
      setVPNRunning(false);
    }
  }

  useImperativeHandle(ref, () => ({
    runVPN,
  }));
  return (
    <>
      <div
        className={`card  ${isVPNSelected ? "border-primary border-2" : ""}`}
      >
        {isVPNRunning ? (
          <div className="progress progress-sm">
            <div className="progress-bar progress-bar-indeterminate "></div>
          </div>
        ) : (
          <></>
        )}
        <div
          className="card-header cursor-pointer"
          style={{ zIndex: 1 }}
          onClick={() => setIsVPNSelected(!isVPNSelected)}
        >
          <div className="subheader text-dark">{serverName}</div>
          <div className="ms-auto lh-1">
            <label className="form-check form-switch m-0 ">
              <input
                className="form-check-input position-static cursor-pointer"
                type="checkbox"
                checked={isVPNSelected}
                onChange={() => setIsVPNSelected(!isVPNSelected)}
              />
            </label>
          </div>
        </div>
        <div className="card-body">
          <div className="h1 mb-3 text-muted">
            {isVPNRunning ? (
              <>
                In Progress <span className="animated-dots"></span>
              </>
            ) : isPassed == null ? (
              <>Not Tested</>
            ) : isPassed ? (
              <span className="text-success d-flex align-items-center">
                <IconCircleCheckFilled className="text-success me-1" />
                Host is Reachable
              </span>
            ) : (
              <span className="text-danger d-flex align-items-center">
                <IconCircleXFilled className="text-danger me-1" />
                Host is Unreachable
              </span>
            )}
          </div>
          <div className="row mb-2">
            <div className="col-6">
              <div className="small fw-bold">Packet Sent:</div>
            </div>
            <div className="col-6 small text-end">
              {isVPNRunning ? (
                <span className="text-muted">
                  <div
                    className="spinner-border spinner-border-sm"
                    role="status"
                  ></div>
                </span>
              ) : (
                <span>{vpnResult.sent}</span>
              )}
            </div>
          </div>
          <div className="row mb-2">
            <div className="col-6">
              <div className="small fw-bold">Packet Received:</div>
            </div>
            <div className="col-6 small text-end">
              {isVPNRunning ? (
                <span className="text-muted">
                  <div
                    className="spinner-border spinner-border-sm"
                    role="status"
                  ></div>
                </span>
              ) : (
                <span>{vpnResult.received}</span>
              )}
            </div>
          </div>
          <div className="row mb-2">
            <div className="col-6">
              <div className="small fw-bold">Packet Loss:</div>
            </div>
            <div className="col-6 small text-end">
              {isVPNRunning ? (
                <span className="text-muted">
                  <div
                    className="spinner-border spinner-border-sm"
                    role="status"
                  ></div>
                </span>
              ) : (
                <span>{vpnResult.loss}</span>
              )}
            </div>
          </div>
          <div className="row mb-2">
            <div className="col-6">
              <div className="small fw-bold">
                Round Trip Time <small className="fw-normal">(Average)</small>:
              </div>
            </div>
            <div className="col-6 small text-end">
              <span>
                {" "}
                {isVPNRunning ? (
                  <span className="text-muted">
                    <div
                      className="spinner-border spinner-border-sm"
                      role="status"
                    ></div>
                  </span>
                ) : (
                  <span>{vpnResult.rtt}</span>
                )}
              </span>
            </div>
          </div>
        </div>
        <div className="card-footer">
          <div className="row">
            <div className="col" style={{ zIndex: 1 }}>
              {isVPNRunning ? (
                <button className="btn btn-primary  w-100 fs-5" disabled>
                  Running <span className="animated-dots"></span>
                </button>
              ) : (
                <button
                  className="btn btn-outline-primary  w-100 fs-5"
                  onClick={runVPN}
                >
                  Run
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
});

export default VPN;
