import "./style.css";
import React from 'react';
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { GoogleReCaptcha, GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { useEffect, useState, useMemo } from "react";
import { useNavigate } from 'react-router-dom';
import { useSelector } from "react-redux";
import { useMsal } from '@azure/msal-react';
import { loginRequest } from "config/authentication/azure/authConfig";
import { setAccessToken } from 'pages/officeMonitoring/services/api/client';
import { getTimesheet, postDailyTimelog } from "./services/api"
import { TimesheetTable } from "./features/tables/components/timesheetTable";
import { parseTimesheet, stringToTime } from './features/tables/utils';
import { IconRefresh } from '@tabler/icons-react';

const Timelog = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [isPosting, setIsPosting] = useState(true);
  const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
  const [clockDate, setClockDate] = useState(new Date());
  setInterval(function() { setClockDate(new Date()); }, 1000);
  const [dateSelected, setDateSelected] = useState(
    useSelector((state) => state.monthSelected.value)
  );
  const navigate = useNavigate();
  const { instance, accounts } = useMsal();
  const renderData = async () => {
    try {
      setIsLoading(true);
      setRefreshReCaptcha(r => !r)
      const { accessToken } = await instance.acquireTokenSilent({
        ...loginRequest,
        account: accounts[0],
      });
      setAccessToken(accessToken);
      setIsPosting(false);
      const data = await getTimesheet(
        dateSelected.startDate,
        dateSelected.endDate
      );
      setTimesheet(data);
      setIsLoading(false);
    } catch (error) {
      if (error.response) {
        navigate(`/${error.response.status}`);
      }
      setIsLoading(false);
    }
  };

  var log = {
    dateTimedIn: null,
    dateTimedOut: null
  }

  useEffect(() => { renderData(); }, [dateSelected]);
  
  const notification = withReactContent(Swal);
  function clockIn(type) {
    setRefreshReCaptcha(r => !r)
    if (token) {
      setIsLoading(true);
      setIsPosting(true);
      postDailyTimelog({
        type: type,
        captcha: token
      }).then(() => {
        if (type === "in") {
          log.dateTimedIn = new Date();
          notification.fire({
            icon: "success",
            title: "Today's Time In: " + stringToTime(log.dateTimedIn),
            text: "Timelog success",
            showConfirmButton: false,
            timer: 2500
          });
        }
        else if (type === "out") {
          log.dateTimedOut = new Date();
          notification.fire({
            icon: "success",
            title: "Today's Time Out: " + stringToTime(log.dateTimedOut),
            text: "Timelog success",
            showConfirmButton: false,
            timer: 2500
          });
        }
      })
      .catch((error) => {
        if (error.response) {
          switch (error.response.status) {
            case 400: {
              notification.fire({
                icon: "warning",
                title: "Captcha Timeout",
                text: "Please reload the page",
                confirmButtonText: <div><IconRefresh /> Reload page</div>
              }).then((result) => {
                if (result.isConfirmed) {
                  window.location.reload();
                }
              });
              break;
            }
            default:
              navigate(`/${error.response.status}`);
          }
        } else {
          notification.fire({
            icon: "error",
            title: "Please contact your administrator",
            text: "Internal server error",
            showConfirmButton: false,
            timer: 1500
          });
        }
      }).finally(() => {
        setRefreshReCaptcha(r => !r)
        setIsLoading(false);
        setIsPosting(false);
        renderData();
      });
    }
  }

  const key = "6LeT-lwpAAAAAMh_AJsEmjAWrP9vM4JfsZ6KnU_7";
  const [timesheet, setTimesheet] = useState({});
  const [timesheetData, setTimesheetData] = useState([]);

  const memoizedGetTimesheet = useMemo(() => parseTimesheet(timesheet), [timesheet]);
  useEffect(() => { setTimesheetData(memoizedGetTimesheet); }, [timesheet]);
    
  const [token, setToken] = useState("");
  const [refreshReCaptcha, setRefreshReCaptcha] = useState(false);
  const verifyRecaptchaCallback = React.useCallback((token) => { setToken(token) }, []);
  return (
    <div className="page">
      <div className="container py-4">
        <div className="card">
          <div className="card-header"><h3>Daily Timelog</h3></div>
          <div className="card-body text-center m-auto">
            <div className="clock" id="clock">
              <div style={{marginTop: '15%', marginBottom: '15%'}}>
                <h1>{(clockDate.getHours() % 12) ? (clockDate.getHours() % 12): 12}:{clockDate.getMinutes() < 10 ? '0': ''}{clockDate.getMinutes()} {clockDate.getHours() >= 12 ? 'PM' : 'AM'}</h1>
                <h2>{days[clockDate.getDay()]}, {clockDate.getDate()} {clockDate.toLocaleString('default', { month: 'long' })}</h2>
              </div>
            </div>
              <GoogleReCaptchaProvider reCaptchaKey={key}>
                <GoogleReCaptcha 
                  className="recaptcha-container" 
                  onVerify={verifyRecaptchaCallback} 
                  refreshReCaptcha={refreshReCaptcha}
                />
              </GoogleReCaptchaProvider>
              <div className="button-container">
                <button className="btn btn-primary" onClick={() => clockIn("in")} type="button" disabled={isPosting}><h1>Time In</h1></button>
                <button className="btn btn-primary m-3" onClick={() => clockIn("out")} type="button" disabled={isPosting}><h1>Time Out</h1></button>
              </div>
            </div>
        </div>
      </div>
      <div className="container py-4">
      <TimesheetTable
        data={timesheetData}
        setDateSelected={setDateSelected}
        dateSelected={dateSelected}
        isLoading={isLoading}
      />
      </div>
    </div>
  );
}

export default Timelog;