import { IconArrowLeft } from "@tabler/icons-react";
export const Unauthorized = () => {
  return (
    <div className="page page-center border-top-wide border-primary">
      <div className="container-tight py-4">
        <div className="empty">
          <div className="empty-header">401</div>
          <p className="empty-title">Oops… Unauthorized</p>
          <p className="empty-subtitle text-muted">
            We are sorry but you don't have permission to access this resource.
            Kindly contact the administrator of this page and ask for
            permission.
          </p>
          <div className="empty-action">
            <a href="/" className="btn btn-primary">
              <IconArrowLeft className="icon" />
              Take me home
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};
