export const tableCustomStyles = {
  table: {
    style: {
      color: "inherit",
      backgroundColor: "transparent",
    },
  },
  head: {
    style: {
      color: "inherit",
      backgroundColor: "transparent",
    },
  },
  subHeader: {
    style: {
      color: "inherit",
      backgroundColor: "transparent",
    },
  },
  headRow: {
    style: {
      color: "inherit",
      textTransform: "uppercase",
      backgroundColor: "transparent",
      fontSize: ".625rem",
      fontWeight: 600,
      letterSpacing: ".04em",
      lineHeight: 1.6,
      paddingTop: "0.5rem",
      paddingBottom: "0.5rem",
    },
  },
  rows: {
    style: {
      color: "inherit",
      backgroundColor: "transparent",
    },
  },
  cells: {
    style: {
      color: "inherit",
      backgroundColor: "transparent",
    },
  },

  headCells: {
    style: {
      color: "inherit",
      backgroundColor: "transparent",
    },
  },
  pagination: {
    style: {
      color: "inherit",
      backgroundColor: "transparent",
    },
  },
};
