import React from "react";
import { useDispatch } from "react-redux";
import { startOfWeek, endOfWeek, format } from "date-fns";
import { DateRangePicker } from "rsuite";
import "rsuite/dist/rsuite.min.css";
import { updateDateRangeSelected } from "pages/officeMonitoring/state/reducers";
import { getWeekNumberByDate } from "../utils";

export const DateRangeSelector = ({ dateSelected, setDateSelected }) => {
  const dispatch = useDispatch();

  return (
    <>
      <DateRangePicker
        // disabledDate={(date) => !isWeekend(date, new Date())}
        // hoverRange="week"
        ranges={[]}
        cleanable={false}
        editable={false}
        value={[
          new Date(dateSelected.startDate),
          new Date(dateSelected.endDate),
        ]}
        placement="bottomEnd"
        onChange={(dateRange) => {
          const startDate = startOfWeek(new Date(dateRange[0]));
          const endDate = endOfWeek(new Date(dateRange[1]));
          const dateSelectedObj = {
            startDate: format(startDate, "yyyy-MM-dd"),
            endDate: format(endDate, "yyyy-MM-dd"),
            startWeek: getWeekNumberByDate(startDate),
            endWeek: getWeekNumberByDate(endDate),
          };
          dispatch(updateDateRangeSelected(dateSelectedObj));
          setDateSelected(dateSelectedObj);
        }}
        format="MMMM dd, yyyy"
      />
    </>
  );
};
