import React, { useEffect, useState } from "react";
import { useMsal } from "@azure/msal-react";
import { selfServiceTokenRequest } from "config/authentication/azure/authConfig";
import { setAccessToken } from "pages/selfHelpDesk/services/api/client";
import { logOffVDI } from "pages/selfHelpDesk/services/api";
import {
  IconCircleCheck,
  IconCircleDotted,
  IconCircleX,
  IconDeviceDesktop,
  IconDeviceDesktopBolt,
  IconDeviceDesktopCheck,
  IconDeviceDesktopDown,
  IconDeviceDesktopOff,
  IconDeviceDesktopPause,
  IconDeviceDesktopX,
  IconMessageCircleQuestion,
  IconQuestionMark,
  IconReload,
} from "@tabler/icons-react";

const VDI = () => {
  const { instance, accounts } = useMsal();
  const [isRunning, setIsRunning] = useState(false);
  const [vDIResult, setVDIResult] = useState({
    statusSubText: "",
    statusText: "",
    statusColor: "text-muted",
  });

  const restartConnection = async () => {
    try {
      setIsRunning(true);
      const { accessToken } = await instance.acquireTokenSilent({
        ...selfServiceTokenRequest,
        account: accounts[0],
      });

      setAccessToken(accessToken);

      await logOffVDI();

      setVDIResult({
        statusText: "Success",
        statusSubText: (
          <span>
            The restart operation for your VDI has been initiated successfully.
            Please note that it may take <strong>3 to 5 minutes</strong> for the
            restart process to be fully completed.
          </span>
        ),
        statusColor: "text-success",
      });

      console.log("okay");
    } catch (err) {
      handleErrorResponse(err);
    } finally {
      setIsRunning(false);
    }
  };

  const handleErrorResponse = (err) => {
    let statusText = "Failed";
    let statusSubText = "Something went wrong. Please try again.";
    if (err.response != null) {
      switch (err.response.status) {
        case 400:
          break;
        case 401:
          statusSubText = "You don't have permission to perform this operation";
          break;
        case 404:
          statusSubText = "You don't have an active session.";
          break;
        case 500:
          break;
        default:
          break;
      }
    }

    setVDIResult({
      statusText,
      statusSubText,
      statusColor: "text-danger",
    });
  };

  useEffect(() => {
    if (isRunning) {
      setVDIResult({
        statusText: "In Progress",
        statusSubText: "Your VDI session is now restarting. Please wait.",
        statusColor: "text-muted",
      });
    }
  }, [isRunning]);

  return (
    <div className="col-md-6">
      <div className="card">
        <div className="progress progress-sm">
          {isRunning && (
            <div className="progress-bar progress-bar-indeterminate "></div>
          )}
        </div>
        <div className="empty">
          {isRunning ? (
            <IconDeviceDesktopBolt
              width={112}
              height={112}
              className="pb-2 text-muted"
            />
          ) : vDIResult.statusText === "Failed" ? (
            <div className="text-danger">
              <IconDeviceDesktopX width={112} height={112} className="pb-2" />
            </div>
          ) : vDIResult.statusText === "Success" ? (
            <div className="text-success">
              <IconDeviceDesktopCheck
                width={112}
                height={112}
                className="pb-2"
              />
            </div>
          ) : (
            <IconDeviceDesktop
              width={112}
              height={112}
              className="pb-2 text-muted"
            />
          )}

          <div className="empty-header text-dark">Azure VDI</div>

          <p className={`empty-title ${vDIResult.statusColor}`}>
            {vDIResult.statusText}{" "}
            {isRunning && <span className="animated-dots"></span>}
          </p>
          <p className="empty-subtitle text-muted">{vDIResult.statusSubText}</p>

          <div className="empty-action">
            <button className="btn btn-primary" onClick={restartConnection}>
              {isRunning ? (
                <>
                  Restarting <span className="animated-dots"></span>
                </>
              ) : (
                <>
                  <IconReload className="icon" />
                  Restart Connection
                </>
              )}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VDI;
