import _ from "lodash";

export const getComplianceRateChartData = (obj) => {
  var overallComplianceRate = obj.teamComplianceRate;
  var compliant = 0;
  var members = _.isEmpty(obj) ? 0 : obj.teamCount;
  const traverse = (obj) => {
    _.forIn(obj, function (val, key) {
      if (key === "complianceRate" && parseInt(val) === 100) {
        compliant++;
      }
      if (_.isArray(val)) {
        val.forEach(function (el) {
          if (_.isObject(el)) {
            traverse(el);
          }
        });
      }
      if (_.isObject(key)) {
        traverse(obj[key]);
      }
    });
  };
  traverse(obj);
  return {
    overallComplianceRate,
    members,
    compliant,
    nonCompliant: members - compliant,
  };
};
