/*Third Party */
import { IconExternalLink } from "@tabler/icons-react";
import {
  IconAlertCircle,
  IconAlertCircleFilled,
  IconCircleCheck,
  IconCircleCheckFilled,
  IconCircleX,
  IconCircleXFilled,
  IconClock,
  IconClockFilled,
  IconInfoCircle,
  IconInfoCircleFilled,
} from "@tabler/icons-react";
import { get8x8 } from "pages/selfHelpDesk/services/api";
import { updateSelectedTests } from "pages/selfHelpDesk/state/reducers";
import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

/*Local Features*/

/* API Methods */

/* Features-Utils */

/* Config */

/*UI Component*/

const EightByEight = forwardRef(({ id }, ref) => {
  const dispatch = useDispatch();
  const [is8x8Selected, setIs8x8Selected] = useState(false);
  const [is8x8Running, setIs8x8Running] = useState(false);
  const [status, setStatus] = useState({
    icon: <></>,
    color: "dark",
    title: "Not Tested",
    subTitle: "",
  });
  const selectedTests = useSelector((state) => state.selectedTests.value);
  useEffect(() => {
    const isSelected = selectedTests.find(
      (metric) => metric.id === id
    )?.isSelected;

    if (isSelected) {
      setIs8x8Selected(isSelected);
    }
  }, [selectedTests]);

  useEffect(() => {
    const updatedSelectedTests = selectedTests.map((test) => {
      if (test.id === id) {
        return { ...test, isSelected: !test.isSelected };
      }
      return test;
    });
    dispatch(updateSelectedTests(updatedSelectedTests));
  }, [is8x8Selected]);

  async function run8x8() {
    if (!is8x8Running) {
      setIs8x8Running(true);
      try {
        const apiResponse = await get8x8();
        const data = apiResponse.data;

        if (data.status === "Normal") {
          setStatus({
            icon: <IconCircleCheckFilled className="text-success me-1" />,
            color: "text-success",
            title: data.status,
            subTitle: "Current Status:",
          });
        } else if (data.status === "Information") {
          setStatus({
            icon: <IconInfoCircleFilled className="text-primary  me-1" />,
            color: "text-primary",
            title: data.status,
            subTitle: "Current Status:",
          });
        } else if (data.status === "Performance Issue") {
          setStatus({
            icon: <IconAlertCircleFilled className="text-warning me-1" />,
            color: "text-warning",
            title: data.status,
            subTitle: "Current Status:",
          });
        } else if (data.status === "Service Outage") {
          setStatus({
            icon: <IconCircleXFilled className="text-danger me-1" />,
            color: "text-danger",
            title: data.status,
            subTitle: "Current Status:",
          });
        } else if (data.status === "Maintenance") {
          setStatus({
            icon: <IconClockFilled className="text-yellow me-1" />,
            color: "text-yellow",
            title: data.status,
            subTitle: "Current Status:",
          });
        } else {
          setStatus({
            icon: <></>,
            color: "text-muted",
            title: data.status,
            subTitle: "Current Status:",
          });
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        setStatus({
          icon: <></>,
          color: "text-danger",
          title: "Unable to fetch",
        });
      }
      setIs8x8Running(false);
    }
  }
  useImperativeHandle(ref, () => ({
    run8x8,
  }));

  return (
    <>
      <div
        className={`card  ${is8x8Selected ? "border-primary border-2" : ""}`}
      >
        {is8x8Running ? (
          <div className="progress progress-sm">
            <div className="progress-bar progress-bar-indeterminate "></div>
          </div>
        ) : (
          <></>
        )}
        <div className="card-header" style={{ zIndex: 1 }}>
          <div className="subheader text-dark d-flex align-items-end  ">
            8x8 Support
            <a href="https://status.8x8.com" target="_blank">
              <IconExternalLink size={16} className="ms-1" />
            </a>
          </div>
          <div className="ms-auto lh-1">
            <label className="form-check form-switch m-0 ">
              <input
                className="form-check-input position-static cursor-pointer"
                type="checkbox"
                checked={is8x8Selected}
                onChange={() => setIs8x8Selected(!is8x8Selected)}
              />
            </label>
          </div>
        </div>

        <div className="card-body">
          <div className="small text-muted">{status.subTitle}</div>
          <div className="h1 mb-3 text-muted">
            {is8x8Running ? (
              <>
                In Progress <span className="animated-dots"></span>
              </>
            ) : (
              <span className={`d-flex align-items-center ${status.color}`}>
                {status.icon}
                {status.title}
              </span>
            )}
          </div>
          <pre className="">
            <div className="col-6">
              <div className="small text-muted pb-1">Event Types:</div>
              <ul className="list-unstyled mb-0">
                <li>
                  <IconCircleCheckFilled
                    width="16"
                    className="text-success me-1"
                  />
                  <small>Normal</small>
                </li>
                <li>
                  <IconInfoCircleFilled
                    width="16"
                    className="text-primary me-1"
                  />
                  <small>
                    <a
                      href="https://status.8x8.com/incidents?IncidentType=5"
                      target="_blank"
                    >
                      Information
                    </a>
                  </small>
                </li>
                <li>
                  <IconAlertCircleFilled
                    width="16"
                    className="text-warning me-1"
                  />
                  <small>
                    <a
                      href="https://status.8x8.com/incidents?IncidentType=4"
                      target="_blank"
                    >
                      Performance Issue
                    </a>
                  </small>
                </li>
                <li>
                  <IconCircleXFilled width="16" className="text-danger me-1" />
                  <small>
                    <a
                      href="https://status.8x8.com/incidents?IncidentType=4"
                      target="_blank"
                    >
                      Service Outage
                    </a>
                  </small>
                </li>
                <li>
                  <IconClockFilled width="16" className="text-yellow me-1" />
                  <small>
                    <a
                      href="https://status.8x8.com/incidents?IncidentType=3"
                      target="_blank"
                    >
                      Maintenance
                    </a>
                  </small>
                </li>
              </ul>
            </div>
          </pre>
        </div>
        <div className="card-footer">
          <div className="row ">
            <div className="col" style={{ zIndex: 1 }}>
              {is8x8Running ? (
                <button className="btn btn-primary  w-100 fs-5" disabled>
                  Running <span className="animated-dots"></span>
                </button>
              ) : (
                <button
                  className="btn btn-outline-primary  w-100 fs-5"
                  onClick={run8x8}
                >
                  Check Status
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
});
export default EightByEight;
